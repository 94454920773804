<template>
  <v-container id="training-plan" fluid tag="section" class="custom-plan container-full-size">
    <v-col cols="12" style="display: flex; justify-content: space-between">
      <div>
        <v-btn v-if="hasBack || (!hasBack && (train || currentWeek))" color="default" class="mr-0" @click="back()">
          {{ str['back'] }}
        </v-btn>
        <v-btn
          v-if="(client && hasBack) || (!hasBack && (train || currentWeek))"
          color="blue"
          class="mr-0"
          style="margin-left: 10px"
          @click="openClient()"
        >
          {{ str['client_details'] }}
        </v-btn>
        <v-btn v-if="client && !hasBack && !train && !currentWeek" color="blue" class="mr-0" @click="openClient()">
          {{ str['client_details'] }}
        </v-btn>
      </div>
      <div v-if="editPermission && (!showSaveOnlyInitialStep || (showSaveOnlyInitialStep && !train))">
        <v-btn v-if="!hideDelete" color="error" style="margin-right: 10px" @click="openDeleteTrainingPlan()">
          {{ str['delete'] }}
        </v-btn>
        <v-btn v-if="client" color="purple" style="margin-right: 10px" @click="convertTrainingPlanToBase()">
          {{ str['create_plan_base'] }}
        </v-btn>
        <v-btn color="success" class="mr-0" @click="save()">
          {{ str['save'] }}
        </v-btn>
      </div>
    </v-col>

    <v-col cols="12">
      <v-card v-if="!train" class="data-container-content-full">
        <v-col v-if="excludeExercises && client && exercisesLoaded" cols="12" class="pb-0">
          <client-notes ref="daysNotes" :client="client" mode="training" :exercises="workoutsDict" />
        </v-col>
        <v-col cols="12" class="data-container-title">
          <v-text-field
            v-if="!showPlaceAndGender && !showLevel && !showPacks"
            v-model="trainingPlan.name"
            :style="{ 'pointer-events': editPermission ? '' : 'none' }"
            :label="str['training_plan_name']"
            class="purple-input"
            style="margin: 0"
            @blur="trainingPlan.name = trainingPlan.name ? trainingPlan.name.trim() : trainingPlan.name"
          />
          <v-row v-if="showPlaceAndGender || showLevel || showPacks" :class="{ 'mb-2': showDates }">
            <v-col :cols="showPacks ? 6 : 12">
              <v-text-field
                v-model="trainingPlan.name"
                :style="{
                  'pointer-events': editPermission ? '' : 'none',
                }"
                :label="str['training_plan_name']"
                class="purple-input"
                style="margin: 0"
                hide-details
                @blur="trainingPlan.name = trainingPlan.name ? trainingPlan.name.trim() : trainingPlan.name"
              />
              <v-text-field
                v-if="hasTranslations && hasTranslations.indexOf('en') > -1"
                v-model="trainingPlan.name_en"
                :style="{
                  'pointer-events': editPermission ? '' : 'none',
                }"
                :label="str['training_plan_name'] + ' (EN)'"
                class="purple-input"
                style="margin: 0"
                hide-details
                @blur="trainingPlan.name_en = trainingPlan.name_en ? trainingPlan.name_en.trim() : trainingPlan.name_en"
              />
              <v-text-field
                v-if="hasTranslations && hasTranslations.indexOf('es') > -1"
                v-model="trainingPlan.name_es"
                :style="{
                  'pointer-events': editPermission ? '' : 'none',
                }"
                :label="str['training_plan_name'] + ' (ES)'"
                class="purple-input"
                style="margin: 0"
                hide-details
                @blur="trainingPlan.name_es = trainingPlan.name_es ? trainingPlan.name_es.trim() : trainingPlan.name_es"
              />
              <v-text-field
                v-if="hasTranslations && hasTranslations.indexOf('fr') > -1"
                v-model="trainingPlan.name_fr"
                :style="{
                  'pointer-events': editPermission ? '' : 'none',
                }"
                :label="str['training_plan_name'] + ' (FR)'"
                class="purple-input"
                style="margin: 0"
                hide-details
                @blur="trainingPlan.name_fr = trainingPlan.name_fr ? trainingPlan.name_fr.trim() : trainingPlan.name_fr"
              />
            </v-col>
            <v-col v-if="showPacks" cols="6">
              <v-autocomplete
                v-model="trainingPlan.packs"
                :label="str['packs']"
                item-text="name"
                item-value="id"
                :items="packs"
                :no-data-text="str['no_data']"
                :multiple="true"
                :search-input.sync="packsSearchInput"
                class="select-wrap-single-line mt-0"
                @blur="packsSearchInput = ''"
              />
            </v-col>
            <v-col v-if="showProposal" cols="12">
              <v-checkbox
                v-model="trainingPlan.proposal"
                :label="str['monthly_proposal']"
                class="form-field-checkbox"
                hide-details
                style="margin-top: 0"
              />
            </v-col>
            <v-col
              v-if="showPlaceAndGender && trngPlanPlaces && trngPlanPlaces.length"
              :cols="12"
              :lg="showDuration ? 3 : 4"
              :md="showDuration ? 3 : 4"
              :sm="12"
              :class="{ 'pt-0': showDates }"
            >
              <v-autocomplete
                v-model="trainingPlan.place"
                :label="str['place']"
                item-text="label"
                item-value="value"
                :items="trngPlanPlaces"
                :no-data-text="str['no_data']"
                hide-details
              />
            </v-col>
            <v-col
              v-if="showPlaceAndGender && trngPlanGenders && trngPlanGenders.length"
              :cols="12"
              :lg="showDuration ? 3 : 4"
              :md="showDuration ? 3 : 4"
              :sm="12"
              :class="{ 'pt-0': showDates }"
            >
              <v-autocomplete
                v-model="trainingPlan.gender"
                :label="str['gender']"
                item-text="label"
                item-value="value"
                :items="trngPlanGenders"
                :no-data-text="str['no_data']"
                hide-details
              />
            </v-col>
            <v-col
              v-if="showLevel"
              :cols="12"
              :lg="showDuration ? 3 : 4"
              :md="showDuration ? 3 : 4"
              :sm="12"
              :class="{ 'pt-0': showDates }"
            >
              <v-autocomplete
                v-model="trainingPlan.level"
                :label="str['level']"
                item-text="label"
                item-value="value"
                :items="trngPlanLevels"
                :no-data-text="str['no_data']"
                hide-details
              />
            </v-col>
            <v-col v-if="showDuration" :cols="12" :lg="3" :md="3" :sm="12" :class="{ 'pt-0': showDates }">
              <v-autocomplete
                v-model="trainingPlan.duration"
                :label="str['duration']"
                item-text="label"
                item-value="value"
                :items="trngPlanDurations"
                :no-data-text="str['no_data']"
                hide-details
              />
            </v-col>
          </v-row>
          <div v-if="showDates" style="display: flex" :style="{ 'pointer-events': editPermission ? '' : 'none' }">
            <div style="width: 100%; margin-right: 10px">
              <v-menu
                v-model="startDateDatepicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field v-model="startDate" :label="str['start_date']" readonly v-bind="attrs" v-on="on" />
                </template>
                <v-date-picker v-model="startDate" style="margin: 0" :locale="datepickerLanguage" @change="startDateDatepicker = false" />
              </v-menu>
            </div>
            <div style="width: 100%; margin-left: 10px">
              <v-menu
                v-model="endDateDatepicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field v-model="endDate" :label="str['end_date']" readonly v-bind="attrs" v-on="on" />
                </template>
                <v-date-picker v-model="endDate" style="margin: 0" :locale="datepickerLanguage" @change="endDateDatepicker = false" />
              </v-menu>
            </div>
          </div>
          <v-row v-if="showDetails || showPlanImage" class="row-flex-bottom-nowrap" :class="{ 'row-input-with-photo': showPlanImage }">
            <v-col v-if="showPlanImage">
              <div class="input-image-field mb-2" style="width: fit-content">
                <h4>
                  {{ str['image'] }}
                </h4>
                <div class="cursor-hover">
                  <v-img
                    v-if="trainingPlan.image && trainingPlan.image.image"
                    :src="trainingPlan.image.image"
                    style="width: 100%; height: 100%"
                    contain
                  />
                  <v-icon v-if="!trainingPlan.image || (trainingPlan.image && !trainingPlan.image.image)" color="success" size="40">
                    mdi-plus-circle
                  </v-icon>
                  <input id="plan-input-image" type="file" accept=".png,.jpg,.jpeg" @change="updatePlanImage" />
                </div>
                <v-icon
                  v-if="trainingPlan.image && trainingPlan.image.image"
                  color="error"
                  size="30"
                  class="cursor-hover input-image-field-remove"
                  @click="updatePlanImage(null)"
                >
                  mdi-close-circle
                </v-icon>
              </div>
            </v-col>
            <v-col v-if="showDetails" :cols="12" class="pb-4">
              <v-textarea
                v-model="trainingPlan.details"
                :style="{
                  'pointer-events': editPermission ? '' : 'none',
                }"
                :label="str['remarks']"
                style="margin: 0"
                hide-details
              />
              <v-textarea
                v-if="hasTranslations && hasTranslations.indexOf('en') > -1"
                v-model="trainingPlan.details_en"
                :style="{
                  'pointer-events': editPermission ? '' : 'none',
                }"
                :label="str['remarks'] + '(EN)'"
                style="margin: 0"
                hide-details
              />
              <v-textarea
                v-if="hasTranslations && hasTranslations.indexOf('es') > -1"
                v-model="trainingPlan.details_es"
                :style="{
                  'pointer-events': editPermission ? '' : 'none',
                }"
                :label="str['remarks'] + '(ES)'"
                style="margin: 0"
                hide-details
              />
              <v-textarea
                v-if="hasTranslations && hasTranslations.indexOf('fr') > -1"
                v-model="trainingPlan.details_fr"
                :style="{
                  'pointer-events': editPermission ? '' : 'none',
                }"
                :label="str['remarks'] + '(FR)'"
                style="margin: 0"
                hide-details
              />
            </v-col>
          </v-row>
          <div v-if="!openWithLink" class="text-right" style="padding-top: 10px">
            <v-btn
              v-if="editPermission && cloneDayCopied"
              color="orange lighten-2"
              class="mr-0"
              style="margin-right: 10px !important"
              @click="addCloneDayCopied()"
            >
              {{ str['add_train_copied'] }}
            </v-btn>
            <v-btn
              v-if="editPermission && showUpdateAllExercises"
              color="secondary"
              class="mr-0"
              style="margin-right: 10px !important"
              :disabled="!trainingPlan.days.length"
              @click="updateSteps()"
            >
              {{ str['update_trains_exercises'] }}
            </v-btn>
            <v-btn v-if="editPermission && (currentWeek || !planWithWeeks)" color="success" class="mr-0" @click="newTrain()">
              {{ str['add_train'] }}
            </v-btn>
            <v-btn v-if="editPermission && !currentWeek && planWithWeeks" color="success" class="mr-0" @click="newWeek()">
              {{ str['add_week'] }}
            </v-btn>
          </div>
        </v-col>
        <v-col v-if="openWithLink" cols="12" class="pt-0 mb-2">
          <v-text-field
            v-model="trainingPlan.details"
            :style="{ 'pointer-events': editPermission ? '' : 'none' }"
            :label="str['link_url']"
            class="purple-input"
            style="margin: 0"
            hide-details
          />
        </v-col>
        <v-col v-if="trainingPlan.days && !openWithLink" cols="12" class="data-container-list-auto">
          <v-row>
            <v-col cols="12" :lg="showTotalVolume ? 6 : 12" :md="showTotalVolume ? 6 : 12" :sm="showTotalVolume ? 6 : 12" xs="12">
              <div>
                <h4 style="margin-bottom: 10px">
                  {{
                    currentWeek || !planWithWeeks
                      ? planWithWeeks
                        ? currentWeek.name + ' - ' + str['trains']
                        : str['trains']
                      : str['weeks']
                  }}
                </h4>
              </div>
              <div v-if="currentDays && !currentDays.length && (!showDayCategory || (showDayCategory && planWithWeeks && !currentWeek))">
                {{ currentWeek || !planWithWeeks ? str['training_plan_without_trains'] : str['training_plan_without_weeks'] }}
              </div>
              <div v-if="showDayCategory && (!planWithWeeks || currentWeek)" class="mb-2">
                <v-tabs v-model="dayCategoryActive" background-color="transparent" color="secondary" style="margin-top: 0" grow show-arrows>
                  <v-tab
                    v-for="(dayCategory, dayCategoryIndex) in dayCategories"
                    v-show="dayCategoryVisible(dayCategory, dayCategoryIndex)"
                    :key="dayCategory.value"
                  >
                    {{ str[dayCategory.label] ? str[dayCategory.label] : dayCategory.label }}
                  </v-tab>
                </v-tabs>
              </div>
              <draggable
                :list="trainingPlan.days"
                class="sortable-list-group"
                :class="{
                  'sortable-list-group-horizontal custom-scroll-x': showDaysStepsSamePage,
                }"
                handle=".handle"
                @start="dragging = true"
                @end="dragging = false"
              >
                <div
                  v-for="(day, index) in trainingPlan.days"
                  v-show="
                    ((planWithWeeks && !day.week && currentWeek && currentWeek.week_id === day.week_id) ||
                      (!currentWeek && day.week) ||
                      !planWithWeeks) &&
                    (!showDayCategory ||
                      (showDayCategory &&
                        (((!planWithWeeks || currentWeek) && day.category === dayCategories[dayCategoryActive].value) ||
                          (planWithWeeks && !currentWeek))))
                  "
                  :key="index"
                  class="sortable-list-group-item sortable-list-group-item-accordion"
                  :class="{
                    'sortable-list-item-error': itemHasError('day', day),
                  }"
                >
                  <div
                    class="sortable-list-group-item-accordion-header"
                    :class="{
                      'sortable-list-item-error-text-color': itemHasError('day', day),
                    }"
                  >
                    <span
                      class="sortable-list-group-item-accordion-header-name"
                      :class="{ 'position-relative': showHoverDaySteps }"
                      @click="openTrain(index)"
                      @mouseover="startOverTrain(index)"
                      @mouseleave="cancelOverTrain(index)"
                    >
                      <span v-if="showDaysStepsSamePage" @click.stop="showDayStepsAccordion(day)">
                        <v-icon
                          v-if="daysAccordionClosed.indexOf(day.id) > -1"
                          color="primary"
                          style="margin-right: 10px"
                          class="cursor-hover"
                        >
                          mdi-chevron-down
                        </v-icon>
                        <v-icon
                          v-if="daysAccordionClosed.indexOf(day.id) === -1"
                          color="primary"
                          style="margin-right: 10px"
                          class="cursor-hover"
                        >
                          mdi-chevron-up
                        </v-icon>
                      </span>
                      <span v-if="!showDaysStepsSamePage">
                        {{ day.name }}
                      </span>
                      <span v-if="showDaysStepsSamePage">
                        <b>{{ day.name }}</b>
                      </span>
                      <div
                        v-if="showHoverDaySteps && trainPopoverActive && trainPopoverActive.index === index"
                        class="plan-popover"
                        @mouseleave="closeTrainPopover(index)"
                        v-html="trainPopoverActive.html"
                      ></div>
                    </span>
                    <span v-if="editPermission" class="sortable-list-group-item-accordion-header-icons">
                      <v-icon v-if="planWithWeeks && day.week" color="success" style="margin-right: 10px" @click="editWeek(day)">
                        mdi-pencil
                      </v-icon>
                      <v-icon v-if="!day.week" color="orange lighten-2" style="margin-right: 10px" @click="copyDay(index)">
                        mdi-content-copy
                      </v-icon>
                      <v-icon color="teal lighten-2" style="margin-right: 10px" @click="duplicateDay(index)">
                        mdi-content-duplicate
                      </v-icon>
                      <v-icon color="error" style="margin-right: 10px" @click="deleteDay(index)">mdi-delete</v-icon>
                      <v-icon color="default" class="handle">mdi-format-align-justify</v-icon>
                    </span>
                  </div>
                  <div
                    v-if="showDaysStepsSamePage && daysAccordionClosed.indexOf(day.id) === -1"
                    class="sortable-list-group-item-accordion-body-full"
                  >
                    <div v-if="!showWorkoutTypeTabs && !showStepCategory && isTrainEmpty(day)">
                      {{ str['train_without_exercises'] }}
                    </div>
                    <div v-if="showWorkoutTypeTabs" class="mb-2">
                      <v-tabs
                        v-model="dayWorkoutTypeTab[day.id]"
                        background-color="transparent"
                        color="secondary"
                        style="margin-top: 0"
                        grow
                        show-arrows
                      >
                        <v-tab v-for="tab in workoutTypesTabs" :key="tab.value">
                          {{ tab.label }}
                        </v-tab>
                      </v-tabs>
                    </div>
                    <div v-if="showStepCategory" class="mb-2">
                      <v-tabs
                        v-model="stepsCategoryActive"
                        background-color="transparent"
                        color="secondary"
                        style="margin-top: 0"
                        grow
                        show-arrows
                      >
                        <v-tab v-for="stepsCategory in stepsCategories" :key="stepsCategory.value">
                          {{ str[stepsCategory.label] ? str[stepsCategory.label] : stepsCategory.label }}
                        </v-tab>
                      </v-tabs>
                    </div>
                    <draggable
                      :list="day.steps"
                      class="sortable-list-group"
                      handle=".handle"
                      @start="startStepSort(day)"
                      @end="endStepSort"
                    >
                      <div
                        v-for="(dayStep, stIndex) in day.steps"
                        v-show="
                          !dayStep.new &&
                          (!showWorkoutTypeTabs ||
                            !workoutTypesTabs[dayWorkoutTypeTab[day.id]] ||
                            (workoutTypesTabs[dayWorkoutTypeTab[day.id]] && workoutTypesTabs[dayWorkoutTypeTab[day.id]].value === null) ||
                            (showWorkoutTypeTabs &&
                              workoutsDict[dayStep.workout_id] &&
                              workoutTypesTabs[dayWorkoutTypeTab[day.id]] &&
                              workoutsDict[dayStep.workout_id].type === workoutTypesTabs[dayWorkoutTypeTab[day.id]].value)) &&
                          (!showStepCategory || (showStepCategory && dayStep.category === stepsCategories[stepsCategoryActive].value))
                        "
                        :key="stIndex"
                        class="sortable-list-group-item-box-parent"
                        :class="{
                          'sortable-list-group-item-circuit-header': isCircuitHeaderStep(dayStep),
                          'sortable-list-group-item-circuit-children': !isCircuitHeaderStep(dayStep) && dayStep.circuit,
                          'sortable-list-group-item-circuit-children-last': isLastCircuitStep(stIndex, day),
                        }"
                      >
                        <div
                          class="sortable-list-group-item-box-content"
                          :class="{
                            'sortable-list-item-error sortable-list-item-error-text-color': itemHasError('step', dayStep),
                          }"
                        >
                          <span v-if="isCircuitHeaderStep(dayStep)" @click="editCircuitStep(stIndex, day)">
                            {{ dayStep.remarks }}{{ dayStep.repeat_id > 1 ? ' - ' + dayStep.repeat_id + 'x' : '' }}
                            <span
                              v-if="isCircuitHeaderStep(dayStep) && isLastCircuitStep(stIndex, day)"
                              style="font-weight: 400"
                              @click.stop="newStep(dayStep, day)"
                            >
                              {{ str['add_exercise'] }}
                            </span>
                          </span>
                          <span
                            v-if="!isCircuitHeaderStep(dayStep)"
                            :class="{
                              'sortable-list-group-item-box-content-large': excludeExercises && client && showExcludeExerciseIcon,
                            }"
                            @click="openStep(stIndex, day)"
                          >
                            <span :class="{ 'warning--text': isWorkoutDeleted(dayStep.workout_id) }">
                              <b>{{ getWorkoutName(dayStep.workout_id) }}</b>
                            </span>
                            <span v-html="getStepInfo(dayStep)"></span>
                          </span>
                          <span v-if="editPermission" class="sortable-list-group-item-accordion-header-icons">
                            <v-icon
                              v-if="isCircuitHeaderStep(dayStep)"
                              color="success"
                              style="margin-right: 10px"
                              @click="newStep(dayStep, day)"
                            >
                              mdi-plus-circle
                            </v-icon>
                            <v-icon
                              v-if="
                                !isWorkoutDeleted(dayStep.workout_id) &&
                                excludeExercises &&
                                client &&
                                showExcludeExerciseIcon &&
                                errorsExercisesIds.indexOf(dayStep.workout_id) > -1
                              "
                              color="success"
                              style="margin-right: 10px"
                              @click="setExcludeExercise(dayStep.workout_id, false)"
                            >
                              mdi-dumbbell
                            </v-icon>
                            <v-icon
                              v-if="
                                !isWorkoutDeleted(dayStep.workout_id) &&
                                excludeExercises &&
                                client &&
                                showExcludeExerciseIcon &&
                                errorsExercisesIds.indexOf(dayStep.workout_id) === -1
                              "
                              color="warning"
                              style="margin-right: 10px"
                              @click="setExcludeExercise(dayStep.workout_id, true)"
                            >
                              mdi-cancel
                            </v-icon>
                            <v-icon
                              v-if="!isWorkoutDeleted(dayStep.workout_id)"
                              color="teal lighten-2"
                              style="margin-right: 10px"
                              @click="duplicateStep(stIndex, day)"
                            >
                              mdi-content-duplicate
                            </v-icon>
                            <v-icon color="error" class="mr-0" @click="deleteStep(stIndex, day)">mdi-delete</v-icon>
                            <v-icon
                              v-if="!isWorkoutDeleted(dayStep.workout_id) && !isCircuitHeaderStep(dayStep)"
                              color="default"
                              class="handle"
                              style="margin-left: 10px"
                            >
                              mdi-format-align-justify
                            </v-icon>
                          </span>
                        </div>
                      </div>
                    </draggable>
                  </div>
                </div>
              </draggable>
            </v-col>
            <v-col v-if="showTotalVolume" cols="12" lg="6" md="6" sm="6" xs="12" style="border-left: 1px solid #ddd">
              <div>
                <h4 style="margin-bottom: 10px">
                  {{ str['total_volume'] }}
                </h4>
              </div>
              <div v-if="currentDays && !currentDays.length">
                {{ currentWeek || !planWithWeeks ? str['training_plan_without_trains'] : str['training_plan_without_weeks'] }}
              </div>
              <div v-if="muscleGroupsVolumesValues" class="volumes-table">
                <table>
                  <thead>
                    <td>
                      {{ str['muscle_group'] }}
                    </td>
                    <td>
                      {{ totalVolumeLabel ? (str[totalVolumeLabel] ? str[totalVolumeLabel] : totalVolumeLabel) : str['weight_str'] }}
                    </td>
                  </thead>
                  <tbody>
                    <tr v-for="(totalVolumeItem, index) in getTotalVolume()" :key="index">
                      <td>
                        {{ totalVolumeItem.title }}
                      </td>
                      <td>
                        {{ totalVolumeItem.value }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-card>

      <!-- Train -->
      <v-card v-if="train" class="data-container-content-full">
        <v-col v-if="excludeExercises && client && exercisesLoaded" cols="12" class="pb-0">
          <client-notes ref="trainNotes" :client="client" mode="training" :exercises="workoutsDict" />
        </v-col>
        <v-col cols="12" class="data-container-title">
          <div v-if="!showDayCategory" class="pb-4">
            <v-text-field
              v-model="train.name"
              :style="{
                'pointer-events': editPermission ? '' : 'none',
              }"
              :label="str['train_name']"
              class="purple-input"
              style="margin: 0"
              hide-details
              @blur="train.name = train.name ? train.name.trim() : train.name"
            />
            <v-text-field
              v-if="hasTranslations && hasTranslations.indexOf('en') > -1"
              v-model="train.name_en"
              :style="{
                'pointer-events': editPermission ? '' : 'none',
              }"
              :label="str['train_name'] + ' (EN)'"
              class="purple-input"
              style="margin: 0"
              hide-details
              @blur="train.name_en = train.name_en ? train.name_en.trim() : train.name_en"
            />
            <v-text-field
              v-if="hasTranslations && hasTranslations.indexOf('es') > -1"
              v-model="train.name_es"
              :style="{
                'pointer-events': editPermission ? '' : 'none',
              }"
              :label="str['train_name'] + ' (ES)'"
              class="purple-input"
              style="margin: 0"
              hide-details
              @blur="train.name_es = train.name_es ? train.name_es.trim() : train.name_es"
            />
            <v-text-field
              v-if="hasTranslations && hasTranslations.indexOf('fr') > -1"
              v-model="train.name_fr"
              :style="{
                'pointer-events': editPermission ? '' : 'none',
              }"
              :label="str['train_name'] + ' (FR)'"
              class="purple-input"
              style="margin: 0"
              hide-details
              @blur="train.name_fr = train.name_fr ? train.name_fr.trim() : train.name_fr"
            />
          </div>
          <v-row v-if="showDayCategory">
            <v-col cols="6">
              <v-text-field
                v-model="train.name"
                :style="{
                  'pointer-events': editPermission ? '' : 'none',
                }"
                :label="str['train_name']"
                class="purple-input"
                style="margin: 0"
                @blur="train.name = train.name ? train.name.trim() : train.name"
              />
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="train.category"
                :label="str['category']"
                item-text="label"
                item-value="value"
                :items="dayCategories"
                :no-data-text="str['no_data']"
              />
            </v-col>
          </v-row>
          <div v-if="showTrainDetails" class="pb-4">
            <v-textarea
              v-model="train.remarks"
              :style="{
                'pointer-events': editPermission ? '' : 'none',
              }"
              :label="str['remarks']"
              style="margin: 0"
              hide-details
            />
            <v-textarea
              v-if="hasTranslations && hasTranslations.indexOf('en') > -1"
              v-model="train.remarks_en"
              :style="{
                'pointer-events': editPermission ? '' : 'none',
              }"
              :label="str['remarks'] + ' (EN)'"
              style="margin: 0"
              hide-details
            />
            <v-textarea
              v-if="hasTranslations && hasTranslations.indexOf('es') > -1"
              v-model="train.remarks_es"
              :style="{
                'pointer-events': editPermission ? '' : 'none',
              }"
              :label="str['remarks'] + ' (ES)'"
              style="margin: 0"
              hide-details
            />
            <v-textarea
              v-if="hasTranslations && hasTranslations.indexOf('fr') > -1"
              v-model="train.remarks_fr"
              :style="{
                'pointer-events': editPermission ? '' : 'none',
              }"
              :label="str['remarks'] + ' (FR)'"
              style="margin: 0"
              hide-details
            />
          </div>
          <div v-if="showTrainImage">
            <div class="input-image-field mb-2" style="width: fit-content">
              <h4>
                {{ str['image'] }}
              </h4>
              <div class="cursor-hover">
                <v-img v-if="train.image && train.image.image" :src="train.image.image" style="width: 100%; height: 100%" contain />
                <v-icon v-if="!train.image || (train.image && !train.image.image)" color="success" size="40">mdi-plus-circle</v-icon>
                <input id="train-input-image" type="file" accept=".png,.jpg,.jpeg" @change="updateTrainImage" />
              </div>
              <v-icon
                v-if="train.image && train.image.image"
                color="error"
                size="30"
                class="cursor-hover input-image-field-remove"
                @click="updateTrainImage(null)"
              >
                mdi-close-circle
              </v-icon>
            </div>
          </div>
          <div class="text-right">
            <v-btn
              v-if="editPermission && showUpdateAllExercises"
              color="secondary"
              style="margin-right: 10px"
              :disabled="!train.steps.length"
              @click="updateSteps()"
            >
              {{ str['update_trains'] }}
            </v-btn>
            <v-btn v-if="editPermission" color="success" class="mr-0" @click="newStep()">
              {{ str['add_exercise'] }}
            </v-btn>
            <v-btn v-if="editPermission && hasCircuits" color="success" class="mr-0" style="margin-left: 10px" @click="openCircuitStep()">
              {{ addCircuitText }}
            </v-btn>
          </div>
        </v-col>
        <v-col v-if="trainingPlan.days" cols="12" class="data-container-list-auto">
          <v-row>
            <v-col cols="12" :lg="showTotalVolume ? 6 : 12" :md="showTotalVolume ? 6 : 12" :sm="showTotalVolume ? 6 : 12" xs="12">
              <div>
                <h4 style="margin-bottom: 10px">
                  {{ str['exercises'] }}
                </h4>
              </div>
              <div v-if="!showWorkoutTypeTabs && !showStepCategory && isTrainEmpty()">
                {{ str['train_without_exercises'] }}
              </div>
              <div v-if="showWorkoutTypeTabs" class="mb-2">
                <v-tabs v-model="workoutTypeTab" background-color="transparent" color="secondary" style="margin-top: 0" grow show-arrows>
                  <v-tab v-for="tab in workoutTypesTabs" :key="tab.value">
                    {{ tab.label }}
                  </v-tab>
                </v-tabs>
              </div>
              <div v-if="showStepCategory" class="mb-2">
                <v-tabs
                  v-model="stepsCategoryActive"
                  background-color="transparent"
                  color="secondary"
                  style="margin-top: 0"
                  grow
                  show-arrows
                >
                  <v-tab v-for="stepsCategory in stepsCategories" :key="stepsCategory.value">
                    {{ str[stepsCategory.label] ? str[stepsCategory.label] : stepsCategory.label }}
                  </v-tab>
                </v-tabs>
              </div>
              <draggable :list="train.steps" class="sortable-list-group" handle=".handle" @start="dragging = true" @end="endStepSort">
                <div
                  v-for="(trainStep, trainStepIndex) in train.steps"
                  v-show="
                    !trainStep.new &&
                    (!showWorkoutTypeTabs ||
                      workoutTypesTabs[workoutTypeTab].value === null ||
                      (showWorkoutTypeTabs &&
                        workoutsDict[trainStep.workout_id] &&
                        workoutsDict[trainStep.workout_id].type === workoutTypesTabs[workoutTypeTab].value)) &&
                    (!showStepCategory || (showStepCategory && trainStep.category === stepsCategories[stepsCategoryActive].value))
                  "
                  :key="trainStepIndex"
                  class="sortable-list-group-item-box-parent"
                  :class="{
                    'sortable-list-group-item-circuit-header': isCircuitHeaderStep(trainStep),
                    'sortable-list-group-item-circuit-children': !isCircuitHeaderStep(trainStep) && trainStep.circuit,
                    'sortable-list-group-item-circuit-children-last': isLastCircuitStep(trainStepIndex),
                  }"
                >
                  <div
                    class="sortable-list-group-item-box-content"
                    :class="{
                      'sortable-list-item-error sortable-list-item-error-text-color': itemHasError('step', trainStep),
                    }"
                  >
                    <span v-if="isCircuitHeaderStep(trainStep)" @click="editCircuitStep(trainStepIndex)">
                      {{ trainStep.remarks }}{{ trainStep.repeat_id > 1 ? ' - ' + trainStep.repeat_id + 'x' : '' }}
                      <span
                        v-if="isCircuitHeaderStep(trainStep) && isLastCircuitStep(trainStepIndex)"
                        style="font-weight: 400"
                        @click.stop="newStep(trainStep)"
                      >
                        {{ str['add_exercise'] }}
                      </span>
                    </span>
                    <span
                      v-if="!isCircuitHeaderStep(trainStep)"
                      :class="{
                        'sortable-list-group-item-box-content-large': excludeExercises && client && showExcludeExerciseIcon,
                      }"
                      @click="openStep(trainStepIndex)"
                    >
                      <span :class="{ 'warning--text': isWorkoutDeleted(trainStep.workout_id) }">
                        {{ getWorkoutName(trainStep.workout_id) }}
                      </span>
                      <span v-html="getStepInfo(trainStep)"></span>
                    </span>
                    <span v-if="editPermission" class="sortable-list-group-item-accordion-header-icons">
                      <v-icon v-if="isCircuitHeaderStep(trainStep)" color="success" style="margin-right: 10px" @click="newStep(trainStep)">
                        mdi-plus-circle
                      </v-icon>
                      <v-icon
                        v-if="
                          !isWorkoutDeleted(trainStep.workout_id) &&
                          excludeExercises &&
                          client &&
                          showExcludeExerciseIcon &&
                          errorsExercisesIds.indexOf(trainStep.workout_id) > -1
                        "
                        color="success"
                        style="margin-right: 10px"
                        @click="setExcludeExercise(trainStep.workout_id, false)"
                      >
                        mdi-dumbbell
                      </v-icon>
                      <v-icon
                        v-if="
                          !isWorkoutDeleted(trainStep.workout_id) &&
                          excludeExercises &&
                          client &&
                          showExcludeExerciseIcon &&
                          errorsExercisesIds.indexOf(trainStep.workout_id) === -1
                        "
                        color="warning"
                        style="margin-right: 10px"
                        @click="setExcludeExercise(trainStep.workout_id, true)"
                      >
                        mdi-cancel
                      </v-icon>
                      <v-icon
                        v-if="!isWorkoutDeleted(trainStep.workout_id)"
                        color="teal lighten-2"
                        style="margin-right: 10px"
                        @click="duplicateStep(trainStepIndex)"
                      >
                        mdi-content-duplicate
                      </v-icon>
                      <v-icon color="error" class="mr-0" @click="deleteStep(trainStepIndex)">mdi-delete</v-icon>
                      <v-icon
                        v-if="!isWorkoutDeleted(trainStep.workout_id) && !isCircuitHeaderStep(trainStep)"
                        color="default"
                        class="handle"
                        style="margin-left: 10px"
                      >
                        mdi-format-align-justify
                      </v-icon>
                    </span>
                  </div>
                </div>
              </draggable>
            </v-col>
            <v-col v-if="showTotalVolume" cols="12" lg="6" md="6" sm="6" xs="12" style="border-left: 1px solid #ddd">
              <div>
                <h4 style="margin-bottom: 10px">
                  {{ str['total_volume'] }}
                </h4>
              </div>
              <div v-if="isTrainEmpty()">
                {{ str['train_without_exercises'] }}
              </div>
              <div v-if="muscleGroupsVolumesValues" class="volumes-table">
                <table>
                  <thead>
                    <td>
                      {{ str['muscle_group'] }}
                    </td>
                    <td>
                      {{ str['weight_str'] }}
                    </td>
                  </thead>
                  <tbody>
                    <tr v-for="(totalVolumeItem, index) in getTotalVolume()" :key="index">
                      <td>
                        {{ totalVolumeItem.title }}
                      </td>
                      <td>
                        {{ totalVolumeItem.value }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </v-col>

    <v-dialog v-if="step" v-model="dialogStep" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['add_exercise'] }}
          </span>
        </v-card-title>
        <v-card-text class="custom-scroll" style="padding-top: 10px; padding-bottom: 10px; max-height: 70vh">
          <v-container style="padding-top: 0; padding-bottom: 0">
            <v-row>
              <v-col cols="12">
                <h4>
                  {{ str['filters'] }}
                </h4>
                <v-row class="mb-2">
                  <v-col v-if="showWorkoutPlace" :cols="6">
                    <v-autocomplete
                      v-model="filters.place"
                      :label="str['place']"
                      item-text="label"
                      item-value="value"
                      :items="workoutPlaces"
                      clearable
                      hide-details
                      :no-data-text="str['no_data']"
                      @change="changeWorkoutFilter()"
                    />
                  </v-col>
                  <v-col v-if="workoutTypes && workoutTypes.length" :cols="6">
                    <v-autocomplete
                      v-model="filters.type"
                      :label="str[filtersNames && filtersNames.type ? filtersNames.type : 'type']"
                      item-text="label"
                      item-value="value"
                      :items="workoutTypes"
                      clearable
                      hide-details
                      :no-data-text="str['no_data']"
                      @change="changeWorkoutFilter()"
                    />
                  </v-col>
                  <v-col v-if="workoutMuscleGroups && workoutMuscleGroups.length" :cols="6">
                    <v-autocomplete
                      v-model="filters.muscle_group"
                      :label="str[filtersNames && filtersNames.muscle_group ? filtersNames.muscle_group : 'muscle_group']"
                      item-text="label"
                      item-value="value"
                      :items="workoutMuscleGroups"
                      clearable
                      hide-details
                      :no-data-text="str['no_data']"
                      @change="changeWorkoutFilter()"
                    />
                  </v-col>
                  <v-col v-if="workoutLevels && workoutLevels.length" :cols="6">
                    <v-autocomplete
                      v-model="filters.level"
                      :label="str['level']"
                      item-text="label"
                      item-value="value"
                      :items="workoutLevels"
                      clearable
                      hide-details
                      :no-data-text="str['no_data']"
                      @change="changeWorkoutFilter()"
                    />
                  </v-col>
                  <v-col v-if="showWorkoutGoalFilter && workoutTargets && workoutTargets.length" :cols="6">
                    <v-autocomplete
                      v-model="filters.target"
                      :label="str['goal']"
                      item-text="label"
                      item-value="value"
                      :items="workoutTargets"
                      clearable
                      hide-details
                      :no-data-text="str['no_data']"
                      @change="changeWorkoutFilter()"
                    />
                  </v-col>
                </v-row>
                <v-divider />
                <h4 style="margin-top: 10px">
                  {{ str['exercise'] }}
                </h4>
                <div v-if="!showStepTable">
                  <v-autocomplete
                    v-model="step.workout_id"
                    :label="str['exercise']"
                    item-text="name"
                    item-value="id"
                    :items="workoutsFiltered"
                    :no-data-text="str['no_data']"
                    :filter="customWorkoutsSearch"
                  >
                    <template #item="{ item, on, attrs }">
                      <v-list-item v-bind="attrs" v-on="on">
                        <v-list-item-content>
                          <v-list-item-title>
                            <div>
                              <div>
                                {{ item.name.trim() }}
                              </div>
                              <v-divider v-if="item.separator" style="position: absolute; bottom: 0; left: 0; width: 100%" />
                            </div>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                  <v-autocomplete
                    v-if="showStepCategory"
                    v-model="step.category"
                    :label="str['category']"
                    item-text="label"
                    item-value="value"
                    :items="stepsCategories"
                    :no-data-text="str['no_data']"
                    :disabled="step.circuit ? true : false"
                  />
                  <div v-if="!hasSegmentedTraining">
                    <v-text-field v-model="step.weight" :label="str['load_kg']" required />
                    <v-text-field
                      v-if="showTotalVolume"
                      v-model="step.repeat_id"
                      :label="str['series']"
                      required
                      oninput="this.value = this.value.replace(/[^0-9-]/g, '').replace(/(\..*)\./g, '$1');"
                    />
                    <div
                      v-if="!showTotalVolume"
                      :class="{
                        'row-flex-align': stepSeriesOptions && stepSeriesOptions.length,
                      }"
                    >
                      <v-text-field v-model="step.repeat_id" :label="str['series']" required />
                      <div v-if="stepSeriesOptions && stepSeriesOptions.length" class="button-icon cursor-hover">
                        <v-icon>mdi-plus-circle</v-icon>
                        <v-select
                          :label="str['series']"
                          item-text="label"
                          item-value="value"
                          :items="stepSeriesOptions"
                          :menu-props="{ contentClass: 'button-icon-select' }"
                          hide-details
                          required
                          @change="step.repeat_id = $event"
                        />
                      </div>
                    </div>
                    <div
                      v-if="showRpe"
                      :class="{
                        'row-flex-align': stepRpeOptions && stepRpeOptions.length,
                      }"
                    >
                      <v-text-field v-model="step.rpe" :label="str['rpe']" required />
                      <div v-if="stepRpeOptions && stepRpeOptions.length" class="button-icon cursor-hover">
                        <v-icon>mdi-plus-circle</v-icon>
                        <v-select
                          :label="str['series']"
                          item-text="label"
                          item-value="value"
                          :items="stepRpeOptions"
                          :menu-props="{ contentClass: 'button-icon-select' }"
                          hide-details
                          required
                          @change="step.rpe = $event"
                        />
                      </div>
                    </div>
                    <v-text-field v-if="showRir" v-model="step.rir" :label="str['rir']" required />
                    <div
                      :class="{
                        'row-flex-align': stepRepsOptions && stepRepsOptions.length,
                      }"
                    >
                      <v-text-field v-model="step.repeat" :label="str['repetitions']" required />
                      <div v-if="stepRepsOptions && stepRepsOptions.length" class="button-icon cursor-hover">
                        <v-icon>mdi-plus-circle</v-icon>
                        <v-select
                          :label="str['series']"
                          item-text="label"
                          item-value="value"
                          :items="stepRepsOptions"
                          :menu-props="{ contentClass: 'button-icon-select' }"
                          hide-details
                          required
                          @change="step.repeat = $event"
                        />
                      </div>
                    </div>
                    <v-text-field v-model="step.value" :label="str['runtime']" required />
                    <div
                      :class="{
                        'row-flex-align': stepRestOptions && stepRestOptions.length,
                      }"
                    >
                      <v-text-field v-model="step.rest" :label="str['rest']" required />
                      <div v-if="stepRestOptions && stepRestOptions.length" class="button-icon cursor-hover">
                        <v-icon>mdi-plus-circle</v-icon>
                        <v-select
                          :label="str['series']"
                          item-text="label"
                          item-value="value"
                          :items="stepRestOptions"
                          :menu-props="{ contentClass: 'button-icon-select' }"
                          hide-details
                          required
                          @change="step.rest = $event"
                        />
                      </div>
                    </div>
                    <v-textarea v-if="showRemarks" v-model="step.remarks" :label="str['remarks']" required />
                  </div>
                  <div v-if="hasSegmentedTraining && segmentedTrainingTypes && segmentedTrainingTypes.length">
                    <v-textarea v-if="showRemarks" v-model="step.remarks" :label="str['remarks']" required />
                    <v-tabs
                      v-model="segmentedTrainingTabActive"
                      background-color="transparent"
                      color="secondary"
                      style="margin-top: 0"
                      grow
                      show-arrows
                    >
                      <v-tab v-for="segmentedTrainingTab in segmentedTrainingTypes" :key="segmentedTrainingTab.id">
                        {{ str[segmentedTrainingTab.label] ? str[segmentedTrainingTab.label] : segmentedTrainingTab.label }}
                      </v-tab>
                    </v-tabs>
                    <div>
                      <div
                        v-for="segmentedTrainingField in segmentedTrainingTypes[segmentedTrainingTabActive].fields"
                        :key="segmentedTrainingField.id"
                      >
                        <v-text-field
                          v-if="segmentedTrainingField.type !== 'checkbox'"
                          v-model="step[segmentedTrainingField.id]"
                          :label="str[segmentedTrainingField.label] ? str[segmentedTrainingField.label] : segmentedTrainingField.label"
                          required
                        />
                        <v-checkbox
                          v-if="segmentedTrainingField.type === 'checkbox'"
                          v-model="step[segmentedTrainingField.id]"
                          :label="str[segmentedTrainingField.label] ? str[segmentedTrainingField.label] : segmentedTrainingField.label"
                          class="form-field-checkbox mt-2"
                          @change="(val) => (step[segmentedTrainingField.id] = val ? 1 : 0)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="showStepTable">
                  <v-autocomplete
                    v-model="step.workout_id"
                    :label="str['exercise']"
                    item-text="name"
                    item-value="id"
                    :items="workoutsFiltered"
                    :no-data-text="str['no_data']"
                    :filter="customWorkoutsSearch"
                  />
                  <v-autocomplete
                    v-if="showStepCategory"
                    v-model="step.category"
                    :label="str['category']"
                    item-text="label"
                    item-value="value"
                    :items="stepsCategories"
                    :no-data-text="str['no_data']"
                    :disabled="step.circuit ? true : false"
                  />
                  <div class="pb-3">
                    <table class="simple-table">
                      <thead>
                        <tr>
                          <th v-for="stepTableHeader in stepTableHeaders" :key="stepTableHeader.id">
                            {{ str[stepTableHeader.name] ? str[stepTableHeader.name] : stepTableHeader.name }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(stepTableRow, stepTableRowIndex) in stepTableRows" :key="stepTableRowIndex">
                          <td v-for="exSerTableCol in stepTableRow" :key="exSerTableCol.id">
                            <span v-if="!exSerTableCol.editable" class="text-nowrap">
                              {{ exSerTableCol.value }}
                            </span>
                            <input v-if="exSerTableCol.editable && !exSerTableCol.items" v-model="exSerTableCol.value" />
                            <v-autocomplete
                              v-if="exSerTableCol.editable && exSerTableCol.items"
                              v-model="exSerTableCol.value"
                              item-text="label"
                              item-value="value"
                              :items="exSerTableCol.items"
                              :no-data-text="str['no_data']"
                              outlined
                              hide-details
                              style="width: 120px"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <v-textarea v-if="showRemarks" v-model="step.remarks" :label="str['remarks']" required />
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="padding-top: 0">
          <v-spacer></v-spacer>
          <v-btn color="default" @click="cancelStep">
            {{ str['cancel'] }}
          </v-btn>
          <v-btn :disabled="!step.workout_id" color="success" @click="saveStep">
            {{ str['save'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="updateStepsData" v-model="dialogUpdateSteps" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['update_trains'] }}
          </span>
        </v-card-title>
        <v-card-text style="padding-top: 10px; padding-bottom: 10px">
          <v-container style="padding-top: 0; padding-bottom: 0">
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="updateStepsData.selected"
                  :items="updateStepsData.list"
                  item-text="label"
                  item-value="value"
                  :label="train ? str['exercises'] : planWithWeeks ? str['weeks'] : str['trains']"
                  multiple
                ></v-select>
                <v-text-field v-model="updateStepsData.weight" :label="str['load_kg']" required />
                <v-text-field
                  v-if="showTotalVolume"
                  v-model="updateStepsData.repeat_id"
                  :label="str['series']"
                  required
                  oninput="this.value = this.value.replace(/[^0-9-]/g, '').replace(/(\..*)\./g, '$1');"
                />
                <v-text-field v-if="!showTotalVolume" v-model="updateStepsData.repeat_id" :label="str['series']" required />
                <v-text-field v-if="showRpe" v-model="updateStepsData.rpe" :label="str['series']" required />
                <v-text-field v-if="showRir" v-model="updateStepsData.rir" :label="str['rir']" required />
                <v-text-field v-model="updateStepsData.repeat" :label="str['repetitions']" required />
                <v-text-field v-model="updateStepsData.value" :label="str['runtime']" required />
                <v-text-field v-model="updateStepsData.rest" :label="str['rest']" required />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="padding-top: 0">
          <v-spacer></v-spacer>
          <v-btn color="default" @click="cancelUpdateSteps">
            {{ str['cancel'] }}
          </v-btn>
          <v-btn color="success" :disabled="!updateStepsData.selected.length" @click="saveUpdateSteps">
            {{ str['save'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogFeedbackDay" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['update_feedback_date'] }}
          </span>
        </v-card-title>

        <v-card-text>
          <v-menu
            v-model="datepickerFeedbackDayMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template #activator="{ on, attrs }">
              <v-text-field v-model="feedbackDay" :label="str['feedback_date']" readonly v-bind="attrs" v-on="on" />
            </template>
            <v-date-picker
              v-model="feedbackDay"
              style="margin: 0"
              :locale="datepickerLanguage"
              @change="datepickerFeedbackDayMenu = false"
            />
          </v-menu>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="default" @click="cancelFeedbackDay()">
            {{ str['cancel'] }}
          </v-btn>
          <v-btn color="success" @click="updateFeedbackDay(feedbackDay)">
            {{ str['confirm'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="planWithWeeks && weekToEdit" v-model="dialogUpdateWeek" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['week_name'] }}
          </span>
        </v-card-title>
        <v-card-text style="padding-top: 10px; padding-bottom: 10px">
          <v-container style="padding-top: 0; padding-bottom: 0">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="weekToEdit.name"
                  :label="str['name']"
                  required
                  @blur="weekToEdit.name = weekToEdit.name ? weekToEdit.name.trim() : weekToEdit.name"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="padding-top: 0">
          <v-spacer></v-spacer>
          <v-btn color="default" @click="cancelWeek">
            {{ str['cancel'] }}
          </v-btn>
          <v-btn color="success" @click="saveWeek">
            {{ str['save'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="hasCircuits && circuitStep" v-model="dialogCircuitStep" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ addCircuitText }}
          </span>
        </v-card-title>
        <v-card-text style="padding-top: 10px; padding-bottom: 10px">
          <v-container style="padding-top: 0; padding-bottom: 0">
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-if="showStepCategory"
                  v-model="circuitStep.category"
                  :label="str['category']"
                  item-text="label"
                  item-value="value"
                  :items="stepsCategories"
                  :no-data-text="str['no_data']"
                />
                <v-text-field
                  v-model="circuitStep.name"
                  :label="str['name']"
                  required
                  @blur="circuitStep.name = circuitStep.name ? circuitStep.name.trim() : circuitStep.name"
                />
                <v-text-field
                  v-model="circuitStep.series"
                  :label="str['series']"
                  required
                  oninput="this.value = this.value.replace(/[^0-9-]/g, '').replace(/(\..*)\./g, '$1');"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="padding-top: 0">
          <v-spacer></v-spacer>
          <v-btn color="default" @click="closeCircuitStep">
            {{ str['cancel'] }}
          </v-btn>
          <v-btn
            color="success"
            :disabled="!circuitStep.name || !circuitStep.series || circuitStep.series === '0'"
            @click="saveCircuitStep"
          >
            {{ str['save'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="baseTrains" v-model="dialogBaseTrains" scrollable persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['trains'] }}
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="dialogBaseTrains" style="height: 500px; padding: 10px 0">
          <v-col cols="12" class="pt-0" style="padding-bottom: 20px; margin-bottom: 10px; border-bottom: 1px solid #ddd">
            <v-row>
              <v-col :cols="12" class="pt-0 pb-0">
                <v-text-field v-model="baseTrainsSearchValue" append-icon="mdi-magnify" :label="str['search']" single-line hide-details />
              </v-col>
            </v-row>
          </v-col>
          <div v-if="!baseTrainsFiltered.length" style="text-align: center; color: #888; padding: 40px 0">
            {{ str['no_data'] }}
          </div>
          <div v-for="baseTrain in baseTrainsFiltered" :key="baseTrain.id" class="box-select-item" @click="selectBaseTrain(baseTrain)">
            {{ baseTrain.name }}
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions style="display: flex; justify-content: center">
          <v-btn color="default" @click="dialogBaseTrains = false">
            {{ str['cancel'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Api from '@/services/Api'
import Utils from '@/services/Utils'
import draggable from 'vuedraggable'
import axios from 'axios'
import moment from 'moment'

export default {
  components: {
    draggable,
    ClientNotes: () => import('@/components/dashboard/client/Notes'),
  },
  props: {
    client: Object,
    plan: Object,
    hasBack: Boolean,
    hideDelete: Boolean,
    mountedCallback: Function,
  },
  data() {
    const user = Utils.getUser()
    const config = user && user.configurations ? user.configurations : null
    const trainingPlan = this.plan ? this.plan : { name: '', days: [] }
    const editPermission = this.client
      ? Utils.hasPermission('trng_plans_edit')
        ? true
        : false
      : Utils.hasPermission('base_trng_plans_edit')
        ? true
        : false

    let showDates = false
    if (this.client && config && config.training_plans && config.training_plans.show_dates) {
      showDates = true
    }

    let showPlaceAndGender = false
    if (config && config.training_plans && config.training_plans.show_place_gender) {
      showPlaceAndGender = true
    }

    let showLevel = false
    if (config && config.training_plans && config.training_plans.show_level) {
      showLevel = true
    }

    let showDuration = false
    if (config && config.training_plans && config.training_plans.show_duration) {
      showDuration = true
    }

    let showRemarks = false
    if (config && config.training_plans && config.training_plans.show_remarks) {
      showRemarks = true
    }

    let showProposal = false
    if (config && config.training_plans && config.training_plans.show_proposal && !this.client) {
      showProposal = true
    }

    let planWithWeeks = false
    if (config && config.training_plans && config.training_plans.plan_with_weeks) {
      if (config.training_plans.weeks_compatibility) {
        if (trainingPlan.days && trainingPlan.days.length) {
          const hasWeeks = trainingPlan.days.find(function (d) {
            return d.week
          })
          if (hasWeeks) {
            planWithWeeks = true
          }
        } else {
          planWithWeeks = true
        }
      } else {
        planWithWeeks = true
      }
    }

    let showDetails = false
    if (config && config.training_plans && config.training_plans.show_details) {
      showDetails = true
    }

    let showTotalVolume = false
    if (config && config.training_plans && config.training_plans.training_volume) {
      showTotalVolume = true
    }

    let totalVolumeLabel = false
    if (config && config.training_plans && config.training_plans.training_volume_label) {
      totalVolumeLabel = config.training_plans.training_volume_label
    }

    let showRpe = false
    if (config && config.training_plans && config.training_plans.show_rpe) {
      showRpe = true
    }

    let showRir = false
    if (config && config.training_plans && config.training_plans.show_rir) {
      showRir = true
    }

    let hasCircuits = false
    if (config && config.training_plans && config.training_plans.has_circuits) {
      hasCircuits = true
    }

    let showPlanImage = false
    if (config && config.training_plans && config.training_plans.show_plan_photo) {
      showPlanImage = true
    }

    let showTrainImage = false
    if (config && config.training_plans && config.training_plans.show_day_photo) {
      showTrainImage = true
    }

    let showTrainDetails = false
    if (config && config.training_plans && config.training_plans.show_day_details) {
      showTrainDetails = true
    }

    let addCircuitText = window.strings['add_circuit']
    if (config && config.training_plans && config.training_plans.circuits_button) {
      addCircuitText = config.training_plans.circuits_button
    }

    let showWorkoutGoalFilter = false
    if (config && config.workouts && config.workouts.has_target) {
      showWorkoutGoalFilter = true
    }

    let showUpdateAllExercises = false
    if (config && config.training_plans && config.training_plans.update_all_exercises) {
      showUpdateAllExercises = true
    }

    let showWorkoutPlace = false
    if (config && config.workouts && config.workouts.has_place) {
      showWorkoutPlace = true
    }

    let showWorkoutTypeTabs = false
    if (config && config.workouts && config.workouts.show_type_tabs) {
      showWorkoutTypeTabs = true
    }

    let autoFilterPlace = false
    if (config && config.workouts && config.training_plans.auto_filter_place) {
      autoFilterPlace = true
    }

    let excludeExercises = false
    if (config && config.training_plans && config.training_plans.exclude_exercises && Utils.hasPermission('user_notes_view')) {
      excludeExercises = true
    }

    let hasValidation = false
    if (config && config.training_plans && config.training_plans.has_validation) {
      hasValidation = true
    }

    let hasTrainsNamesValidation = false
    if (
      user &&
      user.configurations &&
      user.configurations.training_plans &&
      user.configurations.training_plans.show_weeks_progress_table &&
      !user.configurations.training_plans.plan_with_weeks
    ) {
      hasTrainsNamesValidation = true
    }

    let warningSaveMessage = false
    if (config && config.training_plans && config.training_plans.warning_save_message) {
      warningSaveMessage = config.training_plans.warning_save_message
    }

    let showExitWarning = false
    if (config && config.training_plans && config.training_plans.show_exit_warning) {
      showExitWarning = config.training_plans.show_exit_warning
    }

    let openWithLink = false
    if (config && config.training_plans && config.training_plans.open_with_link) {
      openWithLink = true
    }

    let showSaveOnlyInitialStep = false
    if (config && config.training_plans && config.training_plans.show_save_only_initial_step) {
      showSaveOnlyInitialStep = true
    }

    let showHoverDaySteps = false
    if (config && config.training_plans && config.training_plans.show_hover_day_steps) {
      showHoverDaySteps = true
    }

    let showDaysStepsSamePage = false
    if (config && config.training_plans && config.training_plans.show_days_steps_same_page) {
      showDaysStepsSamePage = true
    }

    let showExercisesDisabledByClient = false
    if (config && config.training_plans && config.training_plans.show_exercises_disabled_by_client) {
      showExercisesDisabledByClient = true
    }

    let showStepTable = false
    if (config && config.training_plans && config.training_plans.show_step_table) {
      showStepTable = true
    }

    let showStepCategory = false
    if (config && config.training_plans && config.training_plans.show_step_category) {
      showStepCategory = true
    }

    let showDayCategory = false
    if (config && config.training_plans && config.training_plans.show_day_category) {
      showDayCategory = true
    }

    let showPacks = false
    if (config && config.training_plans && config.training_plans.show_packs) {
      showPacks = true
    }

    let showExcludeExerciseIcon = false
    if (config && config.training_plans && config.training_plans.show_exclude_exercise_icon) {
      showExcludeExerciseIcon = true
    }

    let showAddBaseTrain = false
    if (config && config.training_plans && config.training_plans.show_add_base_train) {
      showAddBaseTrain = true
    }

    let saveChangesFeedback = false
    if (config && config.training_plans && config.training_plans.save_changes_feedback) {
      saveChangesFeedback = config.training_plans.save_changes_feedback
    }

    let hasTranslations = false
    if (config && config.training_plans && config.training_plans.has_translations) {
      hasTranslations = config.training_plans.has_translations
    }

    let showExerciseAlternatives = false
    if (config && config.workouts && config.workouts.has_alternatives) {
      showExerciseAlternatives = true
    }

    let hasMultipleMuscleGroups = false
    if (config && config.workouts && config.workouts.multiple_muscle_grups) {
      hasMultipleMuscleGroups = true
    }

    let hasSegmentedTraining = false
    if (config && config.training_plans && config.training_plans.has_segmented_training) {
      hasSegmentedTraining = true
    }

    let showExercisesRemarks = false
    if (config && config.training_plans && config.training_plans.show_exercises_remarks) {
      showExercisesRemarks = true
    }

    let filtersNames = false
    if (config && config.workouts && config.workouts.filters_names) {
      filtersNames = config.workouts.filters_names
    }

    const workoutTypes = Utils.getWorkoutTypes()
    let workoutTypesTabs = [
      {
        label: window.strings['all'],
        value: null,
      },
    ]
    workoutTypesTabs = workoutTypesTabs.concat(workoutTypes)

    let defaultStartDate = null
    let defaultEndDate = null
    if (this.client && !trainingPlan.start_date && !trainingPlan.end_date) {
      defaultStartDate = moment().subtract(1, 'days').format('YYYY-MM-DD')
      if (config && config.training_plans && config.training_plans.default_start_date) {
        defaultStartDate = moment()
          [
            config.training_plans.default_start_date.calc
          ](config.training_plans.default_start_date.value, config.training_plans.default_start_date.type)
          .format('YYYY-MM-DD')
      }
      defaultEndDate = this.client.payday && this.client.payday !== window.strings['n_a'] ? this.client.payday : ''
      if (config && config.training_plans && config.training_plans.default_end_date) {
        defaultEndDate = moment()
          [
            config.training_plans.default_end_date.calc
          ](config.training_plans.default_end_date.value, config.training_plans.default_end_date.type)
          .format('YYYY-MM-DD')
      }
    }

    return {
      str: window.strings,
      datepickerLanguage: window.datepickerLanguage,
      user: user,
      config: config,
      editPermission: editPermission,
      trainingPlan: trainingPlan,
      showDates: showDates,
      showPlaceAndGender: showPlaceAndGender,
      showLevel: showLevel,
      showDuration: showDuration,
      showProposal: showProposal,
      showDetails: showDetails,
      showTotalVolume: showTotalVolume,
      totalVolumeLabel: totalVolumeLabel,
      showRpe: showRpe,
      showRir: showRir,
      showPlanImage: showPlanImage,
      showTrainImage: showTrainImage,
      showTrainDetails: showTrainDetails,
      addCircuitText: addCircuitText,
      showWorkoutGoalFilter: showWorkoutGoalFilter,
      showUpdateAllExercises: showUpdateAllExercises,
      showWorkoutPlace: showWorkoutPlace,
      excludeExercises: excludeExercises,
      hasValidation: hasValidation,
      hasTrainsNamesValidation: hasTrainsNamesValidation,
      warningSaveMessage: warningSaveMessage,
      showExitWarning: showExitWarning,
      openWithLink: openWithLink,
      showSaveOnlyInitialStep: showSaveOnlyInitialStep,
      showHoverDaySteps: showHoverDaySteps,
      showDaysStepsSamePage: showDaysStepsSamePage,
      showExercisesDisabledByClient: showExercisesDisabledByClient,
      showStepTable: showStepTable,
      showStepCategory: showStepCategory,
      showDayCategory: showDayCategory,
      showPacks: showPacks,
      showExcludeExerciseIcon: showExcludeExerciseIcon,
      showAddBaseTrain: showAddBaseTrain,
      saveChangesFeedback: saveChangesFeedback,
      hasTranslations: hasTranslations,
      showExerciseAlternatives: showExerciseAlternatives,
      hasMultipleMuscleGroups: hasMultipleMuscleGroups,
      hasSegmentedTraining: hasSegmentedTraining,
      showExercisesRemarks: showExercisesRemarks,
      filtersNames: filtersNames,
      imageMaxSize: 600,
      startDate: this.client ? (trainingPlan.start_date ? trainingPlan.start_date : defaultStartDate) : null,
      endDate: this.client ? (trainingPlan.end_date ? trainingPlan.end_date : defaultEndDate) : null,
      dragging: false,
      trainIndex: null,
      train: null,
      workouts: [],
      workoutsFiltered: [],
      workoutsDict: {},
      step: null,
      stepIndex: null,
      dialogStep: false,
      filters: {
        place: null,
        type: null,
        muscle_group: null,
        target: null,
        level: null,
      },
      workoutPlaces: Utils.getWorkoutPlaces(),
      workoutMuscleGroups: Utils.getMuscleGroups(),
      workoutTypes: workoutTypes,
      workoutTargets: Utils.getWorkoutTargets(),
      workoutLevels: Utils.getWorkoutLevels(),
      trngPlanPlaces: Utils.getTrngPlanPlaces(),
      trngPlanGenders: Utils.getTrngPlanGenders(),
      trngPlanLevels: Utils.getTrngPlanLevels(),
      trngPlanDurations: Utils.getTrngPlanDurations(),
      startDateDatepicker: null,
      endDateDatepicker: null,
      dialogFeedbackDay: false,
      feedbackDay: null,
      datepickerFeedbackDayMenu: null,
      dialogUpdateSteps: false,
      updateStepsData: {
        list: [],
        selected: [],
        weight: '',
        repeat_id: '',
        rpe: '',
        rir: '',
        repeat: '',
        value: '',
        rest: '',
      },
      showRemarks: showRemarks,
      planWithWeeks: planWithWeeks,
      currentWeek: null,
      dialogUpdateWeek: false,
      weekToEdit: null,
      muscleGroupsVolumesValues: null,
      muscleGroupsVolumesValuesKeys: ['muscle_gr_1_value', 'muscle_gr_2_value', 'muscle_gr_3_value', 'muscle_gr_4_value'],
      hasCircuits: hasCircuits,
      dialogCircuitStep: false,
      circuitStep: null,
      circuitTag: '#CIRCUITO#',
      cloneDayCopied: window.trainingPlanDayClone,
      showWorkoutTypeTabs: showWorkoutTypeTabs,
      workoutTypesTabs: workoutTypesTabs,
      workoutTypeTab: 0,
      dayWorkoutTypeTab: {},
      autoFilterPlace: autoFilterPlace,
      exercisesLoaded: false,
      trainPopoverActive: null,
      errorsTrainsNames: [],
      errorsExercisesIds: [],
      changes: [],
      workoutSeries: Utils.getWorkoutSeries(),
      stepTableHeaders: [],
      stepTableRows: [],
      stepsCategories: Utils.getWorkoutCategories(),
      stepsCategoryActive: 0,
      dayCategories: Utils.getTrngPlanDayCategories(),
      dayCategoryActive: 0,
      daysAccordionClosed: [],
      trainTemp: null,
      packs: [],
      packsSearchInput: '',
      dialogBaseTrains: false,
      baseTrains: null,
      baseTrainsSearchValue: '',
      segmentedTrainingTypes: Utils.getSegmentedTrainingTypes(),
      segmentedTrainingTabActive: 0,
      stepSeriesOptions: Utils.getStepSeries(),
      stepRpeOptions: Utils.getStepRpe(),
      stepRepsOptions: Utils.getStepReps(),
      stepRestOptions: Utils.getStepRest(),
      workoutStatusDeleted: 1,
    }
  },
  computed: {
    baseTrainsFiltered: function () {
      if (this.baseTrainsSearchValue) {
        const searchValue = this.baseTrainsSearchValue.toLowerCase()
        return this.baseTrains.filter(function (item) {
          return item.name.toLowerCase().indexOf(searchValue) > -1
        })
      }
      return this.baseTrains
    },
    currentDays: function () {
      if (this.planWithWeeks) {
        const days = []
        for (let i = 0; i < this.trainingPlan.days.length; i++) {
          if (
            (!this.trainingPlan.days[i].week && this.currentWeek && this.currentWeek.week_id === this.trainingPlan.days[i].week_id) ||
            (!this.currentWeek && this.trainingPlan.days[i].week)
          ) {
            days.push(this.trainingPlan.days[i])
          }
        }
        return days
      } else {
        return this.trainingPlan.days
      }
    },
    getWorkoutName() {
      return (workoutId) => {
        const workout = this.workoutsDict[workoutId]

        if (workout && workout.status !== this.workoutStatusDeleted) {
          return workout.name
        }

        if (workoutId) {
          return `${this.str['exercise_deleted']} - ${workout ? workout.name : workoutId}`
        }

        return this.str['n_a']
      }
    },
    isWorkoutDeleted() {
      return (workoutId) => {
        const workout = this.workoutsDict[workoutId]
        return !workout || workout.status === this.workoutStatusDeleted
      }
    },
  },
  watch: {
    exercisesLoaded(newVal) {
      if (newVal) {
        this.mounted()
      }
    },
  },
  beforeMount: function () {
    if (!this.user) {
      return false
    }
    this.$isLoading(true)
    this.getWorkouts()
    this.checkTrainingPlanName()
    this.checkTrainingPlanWeeks()
    this.getMuscleGroupsVolumesValues()
    this.setStepTableHeaders()
    this.activeEventListener()
    this.planValidation()
    this.refreshPlanImage()
    this.getPacks()
  },
  beforeDestroy: function () {
    this.removeEventListener()
  },
  methods: {
    back: function (saved) {
      if (this.train) {
        if (this.train.new) {
          if (!this.train.name && !this.train.steps.length) {
            this.trainingPlan.days.splice(this.trainingPlan.days.length - 1, 1)
          } else {
            delete this.train.new
          }
        }
        this.trainIndex = null
        this.train = null
        this.planValidation()
      } else if (this.currentWeek) {
        this.currentWeek = null
        this.planValidation()
      } else {
        this.saved = saved
        this.planValidation()
        if (this.hasBack) {
          this.$router.goBack()
        }
      }
    },
    getMuscleGroupsVolumesValues: function () {
      const self = this
      if (this.showTotalVolume) {
        Api.getSettings(
          {
            id: axios.defaults.ptId,
            useCache: true,
          },
          function (response) {
            if (response.success) {
              const muscleGroupsVolumesValues = {}
              response.data.forEach(function (item) {
                if (self.muscleGroupsVolumesValuesKeys.indexOf(item.name) > -1) {
                  muscleGroupsVolumesValues[item.name] = parseFloat(item.value)
                }
              })
              self.muscleGroupsVolumesValues = muscleGroupsVolumesValues
            } else {
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
          },
        )
      }
    },
    setStepTableHeaders: function () {
      if (this.showStepTable) {
        const headers = [
          { id: 'repeat_id', name: 'type' },
          { id: 'weight', name: 'load_kg' },
        ]
        if (this.showRpe) {
          headers.push({ id: 'rpe', name: 'rpe' })
        }
        if (this.showRir) {
          headers.push({ id: 'rir', name: 'rir' })
        }
        headers.push({ id: 'repeat', name: 'repetitions' }, { id: 'value', name: 'runtime' }, { id: 'rest', name: 'rest' })
        this.stepTableHeaders = headers
      }
    },
    checkTrainingPlanName: function () {
      const config = this.user && this.user.configurations ? this.user.configurations.training_plans : null
      const useBaseName = config && config.use_base_name ? true : false
      let defaultName = ''
      if (config && config.default_name) {
        defaultName = config.default_name
      }
      if (this.trainingPlan && !this.trainingPlan.id && !useBaseName) {
        this.trainingPlan.name = defaultName
      }
    },
    getWorkouts: function () {
      const self = this
      Api.getWorkouts(
        {
          useCache: true,
        },
        function (response) {
          self.$isLoading(false)
          if (response.success) {
            const workouts = response.data
              .filter((d) => d.status !== self.workoutStatusDeleted)
              .sort((a, b) => a.name.localeCompare(b.name))
            self.workouts = workouts
            self.workoutsFiltered = workouts
            response.data.forEach(function (it) {
              self.workoutsDict[it.id] = it
            })
            self.exercisesLoaded = true
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    checkTrainingPlanWeeks: function () {
      if (this.config && this.config.training_plans && this.config.training_plans.weeks && !this.trainingPlan.days.length) {
        for (let i = 0; i < this.config.training_plans.weeks.length; i++) {
          this.saveNewWeek(this.config.training_plans.weeks[i])
        }
      }
    },
    changeWorkoutFilter: function () {
      const self = this
      const clone = JSON.parse(JSON.stringify(this.workouts))
      const workoutsFiltered = []

      for (let i = 0; i < clone.length; i++) {
        const muscleGroupFiltered =
          this.filters.muscle_group === null ||
          this.filters.muscle_group === clone[i].muscle_group ||
          (this.hasMultipleMuscleGroups &&
            (this.filters.muscle_group === clone[i].muscle_group_2 ||
              this.filters.muscle_group === clone[i].muscle_group_3 ||
              this.filters.muscle_group === clone[i].muscle_group_4))
        const typeFiltered = this.filters.type === null || this.filters.type === clone[i].type
        const levelFiltered = this.filters.level === null || this.filters.level === clone[i].level
        const targetFiltered = this.filters.target === null || this.filters.target === clone[i].target
        const placeFiltered = this.filters.place === null || this.filters.place === clone[i].place

        if (muscleGroupFiltered && typeFiltered && levelFiltered && targetFiltered && placeFiltered) {
          workoutsFiltered.push(clone[i])
        }
      }

      if (this.showExercisesDisabledByClient && this.client) {
        const excludeExercises = this.getExcludeExercises()
        if (excludeExercises && excludeExercises.length) {
          workoutsFiltered.map((item) => {
            item.disabled = excludeExercises.indexOf(item.id) > -1
            return item
          })
        }
      }

      try {
        if (this.showExerciseAlternatives && this.step && this.step.workout_id) {
          const stepWorkout = workoutsFiltered.find(function (w) {
            return w.id === self.step.workout_id
          })
          if (stepWorkout && stepWorkout.alternatives) {
            const alternatives = JSON.parse(stepWorkout.alternatives)
            workoutsFiltered.sort((a, b) => {
              const alternativeA = alternatives.indexOf(a.id) > -1
              const alternativeB = alternatives.indexOf(b.id) > -1
              if (alternativeA === alternativeB) {
                return a.name.trim().localeCompare(b.name.trim())
              } else if (alternativeA) {
                return -1
              } else {
                return 1
              }
            })
            let lastAlternative = null
            for (let i = 0; i < workoutsFiltered.length; i++) {
              if (alternatives.indexOf(workoutsFiltered[i].id) > -1) {
                lastAlternative = workoutsFiltered[i]
              }
              if (alternatives.indexOf(workoutsFiltered[i].id) === -1) {
                break
              }
            }
            if (lastAlternative) {
              lastAlternative.separator = true
            }
          }
        }
      } catch {}

      this.workoutsFiltered = workoutsFiltered
        .filter((d) => d.status !== this.workoutStatusDeleted)
        .sort((a, b) => a.name.localeCompare(b.name))
    },
    customWorkoutsSearch(item, search, itemText) {
      const normalize = (str) =>
        str
          ?.toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .trim() || ''
      const normalizedSearch = normalize(search).split(' ')
      const itemName = normalize(itemText)
      return normalizedSearch.every((term) => itemName.includes(term))
    },
    resetFilters: function () {
      this.filters = {
        place: null,
        type: null,
        muscle_group: null,
        target: null,
        level: null,
      }
      this.changeWorkoutFilter()
    },
    getStepInfo: function (step) {
      const self = this
      if (this.hasSegmentedTraining && this.segmentedTrainingTypes && this.segmentedTrainingTypes.length) {
        const typesWithValues = []
        for (let i = 0; i < this.segmentedTrainingTypes.length; i++) {
          for (let f = 0; f < this.segmentedTrainingTypes[i].fields.length; f++) {
            if (step[this.segmentedTrainingTypes[i].fields[f].id]) {
              typesWithValues.push(i)
              break
            }
          }
        }
        if (
          typesWithValues.length > 1 ||
          (typesWithValues.length === 1 &&
            this.segmentedTrainingTypes[typesWithValues[0]] &&
            this.segmentedTrainingTypes[typesWithValues[0]].visible)
        ) {
          let txt = ''
          for (let i = 0; i < typesWithValues.length; i++) {
            const type = this.segmentedTrainingTypes[typesWithValues[i]]
            txt +=
              (i > 0 ? '<br>' : '') +
              getText(
                type.cardio_label && step.cardio
                  ? window.strings[type.cardio_label]
                    ? window.strings[type.cardio_label]
                    : type.cardio_label
                  : type.label,
                type.key,
              )
          }
          return txt
        }
        if (typesWithValues.length === 1) {
          const type = this.segmentedTrainingTypes[typesWithValues[0]]
          return getText(!type.default ? type.label : null, type.key)
        }
        const typeDefault = this.segmentedTrainingTypes.find(function (type) {
          return type.default
        })
        return getText(null, typeDefault ? typeDefault.key : '')
      }
      if (this.showStepTable) {
        let tableTxt = ''
        try {
          if (step.repeat_id) {
            const series = JSON.parse(step.repeat_id)
            const srpe = step.rpe ? JSON.parse(step.rpe) : []
            const srir = step.rir ? JSON.parse(step.rir) : []
            const svalue = step.value ? JSON.parse(step.value) : []
            const srepeat = step.repeat ? JSON.parse(step.repeat) : []
            const sweight = step.weight ? JSON.parse(step.weight) : []
            const srest = step.rest ? JSON.parse(step.rest) : []
            series.forEach((s, i) => {
              let tableValues = ''
              if (srpe[i]) {
                tableValues += srpe[i] + ' ' + window.strings['rpe'] + ' | '
              }
              if (srir[i]) {
                tableValues += srir[i] + ' ' + window.strings['rir'] + ' | '
              }
              if (svalue[i]) {
                tableValues += svalue[i] + ' Exec' + ' | '
              }
              if (srepeat[i]) {
                tableValues += srepeat[i] + ' ' + window.strings['reps'] + ' | '
              }
              if (sweight[i]) {
                tableValues += sweight[i] + ' ' + window.strings['kg'] + ' | '
              }
              if (srest[i]) {
                tableValues += srest[i] + ' ' + window.strings['rest'] + ' | '
              }
              if (tableValues) {
                tableValues = tableValues.substring(0, tableValues.length - 2)
                tableTxt += s + ': ' + tableValues + '<br>'
              }
            })
          }
        } catch {}
        return tableTxt
      }
      return getText()

      function getText(title, key) {
        key = key ? key : ''
        let text = ''
        if (title) {
          text += '<span class="label-underline">' + title + '</span>: '
        }
        if (step[key + 'repeat_id']) {
          text += step[key + 'repeat_id'] + ' ' + window.strings['series'] + ' / '
        }
        if (self.showRpe && step[key + 'rpe']) {
          text += step[key + 'rpe'] + ' ' + window.strings['rpe'] + ' / '
        }
        if (self.showRir && step[key + 'rir']) {
          text += step[key + 'rir'] + ' ' + window.strings['rir'] + ' / '
        }
        if (step[key + 'value']) {
          text += step[key + 'value'] + ' / '
        } else if (step[key + 'repeat']) {
          text += step[key + 'repeat'] + ' ' + window.strings['reps'] + ' / '
        }
        if (step[key + 'weight']) {
          text += step[key + 'weight'] + ' kg / '
        }
        if (step[key + 'rest']) {
          text += step[key + 'rest'] + ' ' + window.strings['rest'] + ' / '
        }
        if (self.showExercisesRemarks && step[key + 'remarks']) {
          text += window.strings['remarks'] + ': "' + step[key + 'remarks'].trim() + '" / '
        }
        if (text) {
          text = text.substring(0, text.length - 2)
        }
        return text
      }
    },
    newTrain: function () {
      const self = this
      this.baseTrainsSearchValue = ''
      if (this.showAddBaseTrain) {
        if (this.baseTrains) {
          this.dialogBaseTrains = true
          return true
        }
        this.$isLoading(true)
        Api.getTrains(
          {
            fast: true,
          },
          function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.baseTrains = response.data
              self.dialogBaseTrains = true
            } else {
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
          },
        )
      } else {
        this.setNewTrain()
      }
    },
    selectBaseTrain: function (item) {
      const self = this
      this.$isLoading(true)
      Api.getTrain(
        {
          id: item.id,
        },
        function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.setNewTrain(response.data[0])
            self.dialogBaseTrains = false
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    setNewTrain: function (data) {
      this.trainingPlan.days.push({
        id: this.getNewDayId(),
        name: data ? data.name : '',
        steps: data ? data.steps : [],
        new: true,
        week_id: this.currentWeek ? this.currentWeek.week_id : null,
        image: {
          id: null,
          image: null,
        },
        category: this.showDayCategory && this.dayCategories[this.dayCategoryActive] ? this.dayCategories[this.dayCategoryActive].value : 0,
      })
      const index = this.trainingPlan.days.length - 1
      this.trainIndex = index
      this.train = this.trainingPlan.days[index]
      this.changes.push(this.changesKeys().dayAdded())
      this.saved = false
    },
    newWeek: function () {
      this.editWeek({
        name: '',
      })
    },
    openTrain: function (index) {
      const self = this
      if (!this.currentWeek) {
        this.dayCategoryActive = 0
      }
      if (this.planWithWeeks && this.trainingPlan.days[index].week) {
        this.currentWeek = this.trainingPlan.days[index]
      } else {
        this.trainIndex = index
        this.train = this.trainingPlan.days[index]
        this.refreshTrainImage()
      }
      this.cancelOverTrain()
      if (this.showStepCategory) {
        const stepsCategoryActiveValue = this.stepsCategoryActive
        this.stepsCategoryActive = 0
        setTimeout(function () {
          self.stepsCategoryActive = stepsCategoryActiveValue
        })
      }
    },
    isTrainEmpty: function (train) {
      if (!train) {
        train = this.train
      }
      if (train && train.steps && train.steps.length) {
        for (let i = 0; i < train.steps.length; i++) {
          if (!train.steps[i].new) {
            return false
          }
        }
      }
      return true
    },
    openDeleteTrainingPlan: function () {
      if (this.train) {
        this.deleteDay(this.trainIndex)
      } else {
        this.deleteTrainingPlan()
      }
    },
    copyDay: function (index) {
      const clone = JSON.parse(JSON.stringify(this.trainingPlan.days[index]))
      const stepFields = [
        'circuit',
        'remarks',
        'repeat',
        'repeat_id',
        'rpe',
        'rir',
        'rest',
        'type',
        'value',
        'weight',
        'workout_id',
        'category',
        'bo_repeat',
        'bo_repeat_id',
        'bo_rest',
        'bo_value',
        'bo_weight',
        'cl_repeat',
        'cl_repeat_id',
        'cl_rest',
        'cl_value',
        'cl_weight',
        'dr_repeat',
        'dr_repeat_id',
        'dr_rest',
        'dr_value',
        'dr_weight',
        'fd_repeat',
        'fd_repeat_id',
        'fd_rest',
        'fd_value',
        'fd_weight',
        'rp_repeat',
        'rp_repeat_id',
        'rp_rest',
        'rp_value',
        'rp_weight',
        'tp_repeat',
        'tp_repeat_id',
        'tp_rest',
        'tp_value',
        'tp_weight',
        'wu_repeat',
        'wu_repeat_id',
        'wu_rest',
        'wu_value',
        'wu_weight',
      ]
      delete clone.id
      delete clone.trng_plan_id
      delete clone.week
      delete clone.week_id
      clone.steps.forEach(function (step) {
        for (const key in step) {
          if (stepFields.indexOf(key) === -1) {
            delete step[key]
          }
        }
      })
      window.trainingPlanDayClone = clone
      this.cloneDayCopied = clone
      this.$notify({
        group: 'alert',
        text: window.strings['data_copied'],
        duration: 2000,
      })
    },
    addCloneDayCopied: function () {
      this.cloneDayCopied.week_id = this.currentWeek ? this.currentWeek.week_id : null
      this.cloneDayCopied.id = this.getNewDayId()
      this.trainingPlan.days.push(this.cloneDayCopied)
      this.changes.push(this.changesKeys().dayPasted(this.cloneDayCopied.name))
      this.saved = false
      this.cloneDayCopied = null
    },
    duplicateDay: function (index) {
      const self = this
      const clone = JSON.parse(JSON.stringify(this.trainingPlan.days[index]))
      clone.id = this.getNewDayId()
      if (clone.week) {
        const newWeekId = this.getNewWeekId()
        this.trainingPlan.days.forEach(function (d) {
          if (clone.week_id === d.week_id) {
            const dClone = JSON.parse(JSON.stringify(d))
            dClone.week_id = newWeekId
            self.trainingPlan.days.push(dClone)
            self.changes.push(self.changesKeys().dayDuplicated(dClone.name))
          }
        })
      } else {
        this.trainingPlan.days.push(clone)
        this.changes.push(this.changesKeys().dayDuplicated(clone.name))
      }
      this.saved = false
    },
    duplicateStep: function (index, train) {
      if (!train) {
        train = this.train
      }
      const clone = JSON.parse(JSON.stringify(train.steps[index]))
      clone.id = this.getNewStepId(train)
      if (this.isCircuitHeaderStep(clone)) {
        clone.circuit = this.getNewCircuitValue()
      }
      train.steps.push(clone)
      this.changes.push(
        this.changesKeys().stepDuplicated(train.name, this.workoutsDict[clone.workout_id] ? this.workoutsDict[clone.workout_id].name : ''),
      )
      this.saved = false
    },
    deleteDay: function (index) {
      const self = this
      this.$confirm(
        window.strings['want_delete'] +
          (this.trainingPlan.days && this.trainingPlan.days[index] ? ' "' + this.trainingPlan.days[index].name + '"' : '') +
          '?',
        '',
        'warning',
        Utils.getAlertOptions(true, true),
      )
        .then(() => {
          self.changes.push(self.changesKeys().dayDeleted(self.trainingPlan.days[index].name))
          self.saved = false
          if (self.planWithWeeks && self.trainingPlan.days[index].week) {
            const weekId = self.trainingPlan.days[index].week_id
            for (let i = self.trainingPlan.days.length - 1; i > -1; i--) {
              if (self.trainingPlan.days[i].week_id === weekId) {
                self.trainingPlan.days.splice(i, 1)
              }
            }
          } else {
            self.trainingPlan.days.splice(index, 1)
          }
          self.train = null
        })
        .catch(() => {})
    },
    deleteTrainingPlan: function () {
      const self = this
      const data = {
        id: this.trainingPlan.id,
        client_id: this.client ? this.client.dbId : null,
      }
      this.$confirm(
        window.strings['want_delete_training_plan'] + (this.trainingPlan.name ? ' "' + this.trainingPlan.name + '"' : '') + '?',
        '',
        'warning',
        Utils.getAlertOptions(true, true),
      )
        .then(() => {
          self.$isLoading(true)
          Api.deleteTrainingPlan(data, function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.back(true)
            } else {
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
          })
        })
        .catch(() => {})
    },
    save: function () {
      const self = this
      this.getTrainingPlan(function (trainingPlan) {
        self.planValidation(function () {
          self.$isLoading(true)
          if (self.trainingPlan.id) {
            Api.editTrainingPlan(trainingPlan, function (response) {
              afterSave(response, true)
            })
          } else {
            Api.newTrainingPlan(trainingPlan, function (response) {
              afterSave(response)
            })
          }
        })
      })

      function afterSave(response, updateFeedbackDay) {
        self.$isLoading(false)
        if (response.success) {
          const hasChanges = self.changes.length ? true : false
          self.trainingPlan = encodeData(response.data)
          self.changes = []
          self.refreshPlanImage()
          if (
            self.client &&
            updateFeedbackDay &&
            self.config.feedback &&
            self.config.feedback.feedback_day &&
            !self.config.feedback.feedback_weekday
          ) {
            self.openFeedbackDay()
          } else {
            self.back(true)
          }
          if (self.client && self.saveChangesFeedback && hasChanges) {
            self.handleSaveChangesFeedback()
          }
        } else {
          self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
        }
      }

      function encodeData(data) {
        if (self.showPacks && data && typeof data.packs === 'string') {
          data.packs = JSON.parse(data.packs)
        }
        return data
      }
    },
    planValidation: function (callback) {
      if (this.hasValidation && this.client && this.excludeExercises) {
        const excludeExercises = this.getExcludeExercises()
        this.errorsExercisesIds = []
        const errorsIds = []
        this.trainingPlan.days.forEach(function (day) {
          const dayExercises = []
          day.steps.forEach(function (step) {
            if (step.workout_id) {
              const exerciseAlreadyExist = dayExercises.indexOf(step.workout_id) > -1 ? true : false
              if (excludeExercises.indexOf(step.workout_id) > -1 || exerciseAlreadyExist) {
                errorsIds.push(step.workout_id)
              }
              if (!exerciseAlreadyExist) {
                dayExercises.push(step.workout_id)
              }
            }
          })
        })
        this.errorsExercisesIds = errorsIds
        if (this.errorsExercisesIds.length && callback) {
          return this.$alert(window.strings['exercises_validation_failed'], '', 'warning', Utils.getAlertOptions())
        }
      }
      if (this.hasTrainsNamesValidation) {
        const trainsNames = []
        const trainsNamesDuplicated = []
        this.trainingPlan.days.forEach(function (day) {
          if (trainsNames.indexOf(day.name) > -1) {
            if (trainsNamesDuplicated.indexOf(day.name) === -1) {
              trainsNamesDuplicated.push(day.name)
            }
          } else {
            trainsNames.push(day.name)
          }
        })
        this.errorsTrainsNames = trainsNamesDuplicated
        if (this.errorsTrainsNames.length && callback) {
          return this.$alert(window.strings['trains_names_validation_failed'], '', 'warning', Utils.getAlertOptions())
        }
      }
      if (this.warningSaveMessage && this.changes.length && callback) {
        return this.$confirm(
          window.strings[this.warningSaveMessage] ? window.strings[this.warningSaveMessage] : this.warningSaveMessage,
          '',
          'warning',
          Utils.getAlertOptions(true, true),
        )
          .then(() => {
            callback()
          })
          .catch(() => {})
      }
      if (callback) {
        callback()
      }
    },
    getExcludeExercises: function () {
      const notes = this.getClientNotes()
      let excludeExercises = []
      if (notes && notes.exclude_exercises) {
        excludeExercises = JSON.parse(JSON.stringify(notes.exclude_exercises))
      }
      try {
        const workoutsFlags = {}
        const items = Utils.getWorkoutFlags()

        if (!items || !items.length) return

        Object.values(this.workouts).forEach((exercise) => {
          if (exercise.flags) {
            items.forEach((item) => {
              if (exercise.flags & item.value) {
                if (!workoutsFlags[item.id]) {
                  workoutsFlags[item.id] = {
                    answer: item.answer,
                    field: item.field,
                    condition: item.condition,
                    data: [],
                  }
                }
                workoutsFlags[item.id].data.push(exercise.id)
              }
            })
          }
        })

        Object.entries(notes).forEach(([id, noteValue]) => {
          const flagData = workoutsFlags[id]

          if (noteValue && flagData) {
            const { answer } = flagData
            let answerValueValid = answer.some((ans) => {
              const match = ans.match(/(\d+)/)
              const answerVal = match ? parseInt(match[0], 10) : null

              if (ans.includes('>') && noteValue > answerVal) return true
              if (ans.includes('<') && noteValue < answerVal) return true
              return answer.includes(noteValue)
            })

            if (answerValueValid) {
              flagData.data.forEach((exerciseId) => {
                if (!excludeExercises.includes(exerciseId)) {
                  excludeExercises.push(exerciseId)
                }
              })
            }
          }
        })
      } catch {}
      return excludeExercises
    },
    getClientNotes: function () {
      let data = {}
      if (this.client) {
        try {
          const notes = JSON.parse(JSON.stringify(this.client.notes))
          data = JSON.parse(notes)
        } catch {}
      }
      return data
    },
    itemHasError: function (type, data) {
      if (
        (this.hasTrainsNamesValidation && this.errorsTrainsNames && this.errorsTrainsNames.length) ||
        (this.hasValidation && this.errorsExercisesIds && this.errorsExercisesIds.length)
      ) {
        try {
          switch (type) {
            case 'day':
              if (
                this.hasTrainsNamesValidation &&
                this.errorsTrainsNames &&
                this.errorsTrainsNames.length &&
                this.errorsTrainsNames.indexOf(data.name) > -1
              ) {
                return true
              }
              for (let s = 0; s < data.steps.length; s++) {
                if (this.errorsExercisesIds.indexOf(data.steps[s].workout_id) > -1) {
                  return true
                }
              }
              break
            case 'step':
              if (this.errorsExercisesIds.indexOf(data.workout_id) > -1) {
                return true
              }
              break
          }
        } catch {}
      }
      return false
    },
    openFeedbackDay: function () {
      this.dialogFeedbackDay = true
      if (this.client.feedback_day === window.strings['n_a']) {
        this.feedbackDay = ''
      } else {
        this.feedbackDay = this.client.feedback_day
      }
    },
    cancelFeedbackDay: function () {
      this.dialogFeedbackDay = false
      this.back(true)
    },
    updateFeedbackDay: function (feedbackDay) {
      const self = this
      const data = {
        id: this.client.id,
        feedback_day: feedbackDay,
        log_source: 'MANUAL-TRAINING-PLAN',
      }

      this.dialogFeedbackDay = false

      Api.updateUser(data, function (response) {
        if (response.success) {
          self.client.setValue('feedback_day', response.data.feedback_day)
          Utils.setStorage('client', self.client)
          self.back(true)
        } else {
          self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
        }
      })
    },
    getTrainingPlan: function (callback, convertToBase) {
      const self = this
      Utils.setStorage('trainingPlan', {
        data: this.trainingPlan,
        client: this.client,
      })
      const trainingPlan = JSON.parse(JSON.stringify(this.trainingPlan))
      const trainsImages = []

      if (this.client && !convertToBase) {
        trainingPlan.client_id = this.client.dbId
        trainingPlan.base = 0
        trainingPlan.start_date = this.startDate

        if (this.changes && this.changes.length) {
          trainingPlan.changes = this.changes.join('\n')
        }

        if (this.showDates) {
          trainingPlan.end_date = this.endDate
        } else {
          const endDateYear = trainingPlan.end_date ? moment(trainingPlan.end_date).format('YYYY') : null
          const currentYear = moment().format('YYYY')
          const updateEndDateValidated = endDateYear && parseInt(endDateYear) - parseInt(currentYear) > 50 ? false : true
          if (updateEndDateValidated) {
            const newEndDate = new Date()
            newEndDate.setFullYear(newEndDate.getFullYear() + 100)
            trainingPlan.end_date = Utils.getFormatDate(newEndDate)
          }
        }
      } else {
        trainingPlan.base = 1
      }

      if (this.showProposal) {
        if (trainingPlan.proposal) {
          trainingPlan.proposal = 1
        } else {
          trainingPlan.proposal = 0
        }
      }

      if (this.showPacks) {
        trainingPlan.packs = JSON.stringify(trainingPlan.packs)
      }

      trainingPlan.days_count = trainingPlan.days.filter(function (tr) {
        return tr.steps && tr.steps.length
      }).length

      if (this.showTrainImage) {
        for (let i = 0; i < trainingPlan.days.length; i++) {
          const train = trainingPlan.days[i]
          trainsImages.push(train)
        }
        return handleTrainImage(0)
      }

      handlePlanImage()

      function handleTrainImage(index) {
        if (!trainsImages[index]) {
          return handlePlanImage()
        }

        if (trainsImages[index].image && !isNaN(trainsImages[index].image)) {
          return handleTrainImage(index + 1)
        }

        if (
          !trainsImages[index].image ||
          (trainsImages[index].image && !trainsImages[index].image.id && !trainsImages[index].image.image)
        ) {
          trainsImages[index].image = null
          return handleTrainImage(index + 1)
        }

        if (trainsImages[index].image && !trainsImages[index].image.updated) {
          trainsImages[index].image = trainsImages[index].image.id
          return handleTrainImage(index + 1)
        }

        self.setImage({
          title: 'train',
          data: trainsImages[index].image,
          callback: function (image) {
            trainsImages[index].image = image ? image.id : null
            return handleTrainImage(index + 1)
          },
        })
      }

      function handlePlanImage() {
        if (self.showPlanImage) {
          return self.setImage({
            title: 'training_plan',
            data: self.trainingPlan.image,
            callback: function (image) {
              trainingPlan.image = image ? image.id : null
              return callback(trainingPlan)
            },
          })
        }
        callback(trainingPlan)
      }
    },
    newStep: function (circuitStep, train) {
      if (!train) {
        train = this.train
      }
      const step = {
        id: this.getNewStepId(train),
        workout_id: null,
        repeat_id: '',
        rpe: '',
        rir: '',
        repeat: '',
        type: 0,
        value: '',
        place: null,
        gender: null,
        level: null,
        proposal: false,
        remarks: '',
        circuit: 0,
        category:
          this.showStepCategory && this.stepsCategories[this.stepsCategoryActive]
            ? this.stepsCategories[this.stepsCategoryActive].value
            : 0,
        new: true,
      }
      this.resetFilters()

      if (this.hasCircuits && circuitStep) {
        step.circuit = circuitStep.circuit
        let index = 0
        for (let i = 0; i < train.steps.length; i++) {
          if (train.steps[i].circuit === step.circuit) {
            index = i
          }
        }
        index += 1
        train.steps.splice(index, 0, step)
        this.openStep(index, train)
      } else {
        train.steps.push(step)
        this.openStep(train.steps.length - 1, train)
      }
    },
    getNewDayId: function () {
      let newId = 0
      this.trainingPlan.days.forEach(function (day) {
        if (day.id && day.id > newId) {
          newId = day.id
        }
      })
      return newId + 1
    },
    getNewStepId: function (train) {
      if (!train) {
        train = this.train
      }
      let newId = 0
      train.steps.forEach(function (step) {
        if (step.id && step.id > newId) {
          newId = step.id
        }
      })
      return newId + 1
    },
    isCircuitHeaderStep: function (step) {
      return this.hasCircuits && step.repeat === this.circuitTag ? true : false
    },
    isLastCircuitStep: function (index, train) {
      if (!train) {
        train = this.train
      }
      const step = train.steps[index]
      if (this.hasCircuits && step.circuit) {
        let lastIndex = 0
        for (let i = 0; i < train.steps.length; i++) {
          if (train.steps[i].circuit === step.circuit && !step.new) {
            lastIndex = i
          }
        }
        return lastIndex === index ? true : false
      } else {
        return false
      }
    },
    startStepSort: function (train) {
      this.dragging = true
      this.trainTemp = train
    },
    endStepSort: function (event) {
      const train = this.train ? this.train : this.trainTemp
      this.dragging = false
      if (!this.hasCircuits) {
        return true
      }
      const newIndex = event.newIndex
      if (dragInCircuit()) {
        this.trainTemp = null
        return true
      }
      if (dragOutCircuit()) {
        this.trainTemp = null
        return true
      }
      this.trainTemp = null

      function dragInCircuit() {
        const prev = train.steps[newIndex - 1]
        const next = train.steps[newIndex + 1]
        if (prev && prev.circuit && next && next.circuit) {
          train.steps[newIndex].circuit = prev.circuit
          return true
        }
      }

      function dragOutCircuit() {
        if (train.steps[newIndex].circuit) {
          const prev = train.steps[newIndex - 1]
          if (prev && prev.circuit === train.steps[newIndex].circuit) {
            return true
          }
          train.steps[newIndex].circuit = 0
          return true
        }
      }
    },
    openCircuitStep: function () {
      this.circuitStep = {
        name: '',
        series: '1',
        editIndex: null,
        category:
          this.showStepCategory && this.stepsCategories[this.stepsCategoryActive]
            ? this.stepsCategories[this.stepsCategoryActive].value
            : 0,
      }
      this.trainTemp = null
      this.dialogCircuitStep = true
    },
    closeCircuitStep: function () {
      this.dialogCircuitStep = false
      this.circuitStep = null
      this.trainTemp = null
    },
    editCircuitStep: function (index, train) {
      if (train) {
        this.trainTemp = train
      } else {
        train = this.train
      }
      const step = train.steps[index]
      this.circuitStep = {
        name: step.remarks,
        series: step.repeat_id,
        editIndex: index,
        category: step.category,
      }
      this.dialogCircuitStep = true
    },
    saveCircuitStep: function () {
      const self = this
      const train = this.train ? this.train : this.trainTemp
      let circuitValue = null
      this.resetFilters()

      if (this.circuitStep.editIndex !== null) {
        circuitValue = train.steps[this.circuitStep.editIndex].circuit
        train.steps[this.circuitStep.editIndex].repeat_id = this.circuitStep.series
        train.steps[this.circuitStep.editIndex].remarks = this.circuitStep.name
        if (this.showStepCategory) {
          train.steps[this.circuitStep.editIndex].category = this.circuitStep.category
        }
      } else {
        circuitValue = this.getNewCircuitValue()
        train.steps.push({
          workout_id: null,
          repeat_id: this.circuitStep.series,
          repeat: this.circuitTag,
          type: 0,
          value: '',
          place: null,
          gender: null,
          level: null,
          proposal: false,
          remarks: this.circuitStep.name,
          circuit: circuitValue,
          category: this.circuitStep.category,
        })
      }

      if (this.showStepCategory) {
        train.steps.forEach(function (st) {
          if (!self.isCircuitHeaderStep(st) && st.circuit === circuitValue) {
            st.category = self.circuitStep.category
          }
        })
      }

      this.changes.push(this.changesKeys().circuitUpdated(train.name, this.circuitStep.name))
      this.saved = false
      this.closeCircuitStep()
    },
    getNewCircuitValue: function () {
      let lastCircuit = 1
      for (let i = 0; i < this.train.steps.length; i++) {
        if (this.train.steps[i].circuit) {
          lastCircuit = this.train.steps[i].circuit > lastCircuit ? this.train.steps[i].circuit : lastCircuit
        }
      }
      lastCircuit += 1
      return lastCircuit
    },
    openStep: function (index, train) {
      if (this.editPermission) {
        const trainsInSamePage = train ? true : false
        if (train) {
          this.trainTemp = train
        } else {
          train = this.train
        }
        this.stepIndex = index
        this.step = JSON.parse(JSON.stringify(train.steps[index]))
        this.setStepTable()
        this.dialogStep = true

        let filtersUpdated = false
        if (trainsInSamePage) {
          if (
            this.showWorkoutTypeTabs &&
            this.workoutTypesTabs[this.dayWorkoutTypeTab[train.id]] &&
            this.workoutTypesTabs[this.dayWorkoutTypeTab[train.id]].value !== null
          ) {
            this.filters.type = this.workoutTypesTabs[this.dayWorkoutTypeTab[train.id]].value
            filtersUpdated = true
          }
        } else {
          if (this.showWorkoutTypeTabs && this.workoutTypesTabs[this.workoutTypeTab].value !== null) {
            this.filters.type = this.workoutTypesTabs[this.workoutTypeTab].value
            filtersUpdated = true
          }
        }
        if (this.autoFilterPlace && this.step.new) {
          this.filters.place = this.trainingPlan.place
          filtersUpdated = true
        }
        if (filtersUpdated || this.showExercisesDisabledByClient) {
          this.changeWorkoutFilter()
        }

        this.setDefaultSegmentedTrainingTabActive()
      }
    },
    setDefaultSegmentedTrainingTabActive: function () {
      if (this.hasSegmentedTraining && this.segmentedTrainingTypes && this.segmentedTrainingTypes.length) {
        const defaultIndex = this.segmentedTrainingTypes.findIndex(function (type) {
          return type.default
        })
        this.segmentedTrainingTabActive = defaultIndex > -1 ? defaultIndex : 0
      }
    },
    setStepTable: function () {
      if (this.showStepTable) {
        const self = this
        const rows = []
        if (this.workoutSeries) {
          const keysValues = {}
          this.stepTableHeaders.forEach(function (item) {
            const values = self.step[item.id] && self.step[item.id].includes('[') ? JSON.parse(self.step[item.id]) : []
            keysValues[item.id] = []
            for (let i = 0; i < self.workoutSeries.items.length; i++) {
              keysValues[item.id].push(values[i] ? values[i] : '')
            }
          })
          this.workoutSeries.items.forEach(function (ser, serIndex) {
            const row = []
            self.stepTableHeaders.forEach(function (header, headerIndex) {
              const rowPayload = {
                id: header.id,
                value: keysValues[header.id][serIndex],
                editable: true,
                save: true,
              }
              if (headerIndex === 0) {
                rowPayload.items = self.workoutSeries.items
              }
              row.push(rowPayload)
            })
            rows.push(row)
          })
        }
        this.stepTableRows = rows
      }
    },
    cancelStep: function () {
      const train = this.train ? this.train : this.trainTemp
      if (this.step.new) {
        train.steps.splice(this.stepIndex, 1)
      }
      this.stepIndex = null
      this.step = null
      this.dialogStep = false
      this.trainTemp = null
      this.resetFilters()
    },
    saveStep: function () {
      const train = this.train ? this.train : this.trainTemp
      delete this.step.new
      this.saveStepTable()
      train.steps[this.stepIndex] = this.step
      this.stepIndex = null
      this.changes.push(
        this.changesKeys().stepUpdated(
          train.name,
          this.workoutsDict[this.step.workout_id] ? this.workoutsDict[this.step.workout_id].name : '',
        ),
      )
      this.saved = false
      this.step = null
      this.dialogStep = false
      this.trainTemp = null
      this.resetFilters()
    },
    saveStepTable: function () {
      if (this.showStepTable) {
        const values = {}
        this.stepTableRows.forEach(function (row) {
          row.forEach(function (item) {
            if (!values[item.id]) {
              values[item.id] = []
            }
            values[item.id].push(item.value)
          })
        })
        for (const key in values) {
          this.step[key] = JSON.stringify(values[key])
        }
      }
    },
    deleteStep: function (index, train) {
      if (!train) {
        train = this.train
      }
      const step = train.steps[index]
      const circuitHeaderStep = this.isCircuitHeaderStep(step)
      this.changes.push(
        this.changesKeys().stepDeleted(train.name, this.workoutsDict[step.workout_id] ? this.workoutsDict[step.workout_id].name : ''),
      )
      this.saved = false
      if (circuitHeaderStep) {
        for (let i = train.steps.length - 1; i > -1; i--) {
          if (step.circuit === train.steps[i].circuit) {
            train.steps.splice(i, 1)
          }
        }
      } else {
        train.steps.splice(index, 1)
      }
    },
    updateSteps: function () {
      const self = this
      let list = []
      if (this.train) {
        list = this.train.steps.map((step) => ({
          value: step.id,
          label: self.workoutsDict[step.workout_id] ? self.workoutsDict[step.workout_id].name : '',
        }))
      } else {
        list = this.trainingPlan.days.filter((day) => !day.week).map((day) => ({ value: day.id, label: day.name }))
      }
      this.updateStepsData = {
        list: list,
        selected: [],
        weight: '',
        repeat_id: '',
        rpe: '',
        rir: '',
        repeat: '',
        value: '',
        rest: '',
      }
      this.dialogUpdateSteps = true
    },
    cancelUpdateSteps: function () {
      this.dialogUpdateSteps = false
    },
    saveUpdateSteps: function () {
      const self = this
      if (this.train) {
        for (let i = 0; i < this.train.steps.length; i++) {
          if (this.updateStepsData.selected.indexOf(this.train.steps[i].id) > -1) {
            for (const key in this.updateStepsData) {
              this.train.steps[i][key] = this.updateStepsData[key]
            }
          }
        }
      } else {
        for (let i = 0; i < this.trainingPlan.days.length; i++) {
          if (this.updateStepsData.selected.indexOf(this.trainingPlan.days[i].id) > -1) {
            for (let s = 0; s < this.trainingPlan.days[i].steps.length; s++) {
              for (const key in this.updateStepsData) {
                this.trainingPlan.days[i].steps[s][key] = this.updateStepsData[key]
              }
            }
          }
        }
      }
      this.dialogUpdateSteps = false
      const names = []
      if (this.updateStepsData.list && this.updateStepsData.list.length) {
        this.updateStepsData.list.forEach(function (it) {
          if (self.updateStepsData.selected.indexOf(it.value) > -1) {
            names.push(it.label)
          }
        })
      }
      this.changes.push(this.changesKeys().trainsExercisesUpdated(names.length ? names.join(', ') : null))
      this.saved = false
    },
    editWeek: function (week) {
      this.originalWeekToEdit = JSON.parse(JSON.stringify(week))
      this.weekToEdit = week
      this.dialogUpdateWeek = true
    },
    cancelWeek: function () {
      this.weekToEdit.name = this.originalWeekToEdit.name
      this.dialogUpdateWeek = false
    },
    saveWeek: function () {
      this.dialogUpdateWeek = false
    },
    saveNewWeek: function (name) {
      const newWeekId = this.getNewWeekId()
      this.trainingPlan.days.push({
        name: name,
        steps: [],
        week: 1,
        week_id: newWeekId,
        new: true,
      })
      if (this.config && this.config.training_plans && this.config.training_plans.week_days) {
        for (let i = 0; i < this.config.training_plans.week_days.length; i++) {
          this.trainingPlan.days.push({
            name: this.config.training_plans.week_days[i],
            steps: [],
            new: true,
            week_id: newWeekId,
          })
        }
      }
      this.changes.push(this.changesKeys().weekUpdated(name))
      this.saved = false
    },
    getNewWeekId: function () {
      let newWeekId = 1
      for (let i = 0; i < this.trainingPlan.days.length; i++) {
        const weekId = this.trainingPlan.days[i].week_id
        newWeekId = weekId >= newWeekId ? weekId + 1 : newWeekId
      }
      return newWeekId
    },
    openClient: function () {
      window.clientPageCacheList = null
      const routeData = this.$router.resolve({
        path: '/home/client',
        query: { tab: 'details' },
      })
      localStorage.setItem(
        'open_url_new_page',
        JSON.stringify({
          url: routeData.href,
          data: window.dashboardSessionStorage,
        }),
      )
      window.open(location.origin, '_blank')
    },
    getTotalVolume: function (dayOpened) {
      const self = this

      // Get workouts ids
      const steps = []
      if (dayOpened) {
        dayOpened.steps.forEach(function (step) {
          steps.push(step)
        })
      } else {
        this.trainingPlan.days.forEach(function (day) {
          day.steps.forEach(function (step) {
            steps.push(step)
          })
        })
      }

      // Get workouts muscle groups volumes
      const muscleGroupsData = {}
      steps.forEach(function (step) {
        const workout = self.workoutsDict[step.workout_id] ? self.workoutsDict[step.workout_id] : {}
        const series = step.repeat_id ? parseInt(step.repeat_id) : 1
        for (let s = 0; s < series; s++) {
          setMuscleGroupVolume(workout, 'muscle_group', 0)
          setMuscleGroupVolume(workout, 'muscle_group_2', 1)
          setMuscleGroupVolume(workout, 'muscle_group_3', 2)
          setMuscleGroupVolume(workout, 'muscle_group_4', 3)
        }
      })

      // Get total volumes
      const totalVolumes = []
      const muscleGroups = Utils.getMuscleGroups()
      muscleGroups.forEach(function (muscleGroup) {
        if (muscleGroup.value) {
          totalVolumes.push({
            title: muscleGroup.label,
            value: muscleGroupsData[muscleGroup.value] ? muscleGroupsData[muscleGroup.value] : 0,
          })
        }
      })

      return totalVolumes

      function setMuscleGroupVolume(workout, id, index) {
        if (workout[id]) {
          if (!muscleGroupsData[workout[id]]) {
            muscleGroupsData[workout[id]] = 0
          }
          if (workout[id]) {
            muscleGroupsData[workout[id]] += self.muscleGroupsVolumesValues[self.muscleGroupsVolumesValuesKeys[index]]
          }
        }
      }
    },
    refreshPlanImage: function () {
      const self = this

      if (!this.showPlanImage) {
        return false
      }

      if (!this.trainingPlan.image) {
        return this.$set(this.trainingPlan, 'image', {
          id: null,
          image: '',
        })
      }

      if (!this.trainingPlan.image.image && this.trainingPlan.image && !isNaN(parseInt(this.trainingPlan.image))) {
        return this.getImage(this.trainingPlan.image, function (image) {
          if (image) {
            self.$set(self.trainingPlan, 'image', {
              id: self.trainingPlan.image,
              image: image.file,
            })
          } else {
            self.$set(self.trainingPlan, 'image', {
              id: null,
              image: '',
            })
          }
        })
      }
    },
    refreshTrainImage: function () {
      const self = this

      if (!this.showTrainImage || !this.train) {
        return false
      }

      if (!this.train.image) {
        return this.$set(this.train, 'image', {
          id: null,
          image: '',
        })
      }

      if (!this.train.image.image && this.train.image && !isNaN(parseInt(this.train.image))) {
        return this.getImage(this.train.image, function (image) {
          if (image) {
            self.$set(self.train, 'image', {
              id: self.train.image,
              image: image.file,
            })
          } else {
            self.$set(self.train, 'image', {
              id: null,
              image: '',
            })
          }
        })
      }
    },
    updatePlanImage(event) {
      this.updateImage({
        event: event,
        isTrain: false,
      })
    },
    updateTrainImage(event) {
      this.updateImage({
        event: event,
        isTrain: true,
      })
    },
    updateImage: function (args) {
      const self = this
      const event = args.event
      const isTrain = args.isTrain
      this.$isLoading(true)

      if (event) {
        const file = event.srcElement.files[0]
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function (e) {
          const image = new Image()
          image.src = reader.result
          image.onload = function () {
            if (image.width < self.imageMaxSize && image.height < self.imageMaxSize) {
              saveImage(e.target.result)
            } else {
              saveImage(resizeImage(image))
            }
          }
        }
        reader.onerror = function () {
          self.$isLoading(false)
          self.$alert(window.strings['common_error'], '', 'warning', Utils.getAlertOptions())
        }
      } else {
        saveImage(null)
      }

      function resizeImage(image) {
        const canvas = document.createElement('canvas')
        let width = image.width
        let height = image.height

        if (width > height) {
          if (width > self.imageMaxSize) {
            height *= self.imageMaxSize / width
            width = self.imageMaxSize
          }
        } else {
          if (height > self.imageMaxSize) {
            width *= self.imageMaxSize / height
            height = self.imageMaxSize
          }
        }
        canvas.width = width
        canvas.height = height
        const ctx = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0, width, height)
        return canvas.toDataURL()
      }

      function saveImage(image) {
        if (isTrain) {
          self.$set(self.train, 'image', {
            id: image ? self.train.image.id : null,
            image: image,
            updated: true,
          })
          document.getElementById('train-input-image').value = ''
        } else {
          self.$set(self.trainingPlan, 'image', {
            id: image ? self.trainingPlan.image.id : null,
            image: image,
            updated: true,
          })
          document.getElementById('plan-input-image').value = ''
        }
        self.$isLoading(false)
      }
    },
    setImage: function (args) {
      const self = this
      const title = args.title
      const data = args.data
      const callback = args.callback

      if (!data.image && data.id) {
        return callback(null)
      }

      const payload = {
        name: 'image_' + title + '_' + new Date().getTime(),
        type: 1,
        file: data.image,
      }

      if (data.id) {
        payload.id = data.id
        this.$isLoading(true)
        return Api.updateImage(payload, function (response) {
          self.$isLoading(false)
          if (response.success) {
            callback(response.data)
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        })
      }

      if (data.image) {
        this.$isLoading(true)
        return Api.newImage(payload, function (response) {
          self.$isLoading(false)
          if (response.success) {
            callback(response.data)
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        })
      }

      return callback(null)
    },
    getImage: function (id, callback) {
      const self = this
      this.$isLoading(true)
      Api.getImage(id, function (response) {
        self.$isLoading(false)
        if (response.success) {
          callback(response.data[0])
        } else {
          self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
        }
      })
    },
    getPacks: function () {
      if (this.showPacks) {
        const self = this
        try {
          if (!this.trainingPlan.packs) {
            this.trainingPlan.packs = []
          }
          if (typeof this.trainingPlan.packs === 'string') {
            this.trainingPlan.packs = JSON.parse(this.trainingPlan.packs)
          }
          Api.getPaymentsPacks(
            {
              fields: ['id', 'name'],
            },
            function (response) {
              if (response.success) {
                self.packs = response.data
              } else {
                self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
              }
            },
          )
        } catch {}
      }
    },
    convertTrainingPlanToBase: function () {
      const self = this
      this.$prompt(this.str['enter_training_plan_base_name'], '', '', '', Utils.getAlertOptions())
        .then((name) => {
          self.$isLoading(true)
          self.getTrainingPlan(function (trainingPlan) {
            trainingPlan.name = name
            Api.newTrainingPlan(trainingPlan, function (response) {
              self.$isLoading(false)
              if (response.success) {
                self.$alert(window.strings['training_plan_base_added'], '', 'success', Utils.getAlertOptions())
              } else {
                self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
              }
            })
          }, true)
        })
        .catch(() => {})
    },
    startOverTrain: function (index) {
      const self = this
      if (this.showHoverDaySteps) {
        const day = this.trainingPlan.days[index]
        if (day && day.steps && day.steps.length) {
          this.overTrainActive = true
          clearTimeout(this.overTrainPromise)
          this.overTrainPromise = setTimeout(function () {
            if (self.overTrainActive) {
              let html = '<b class="plan-popover-title">' + day.name + '</b>'
              day.steps.forEach(function (step) {
                if (step.repeat === self.circuitTag) {
                  html += '<b>' + window.strings['circuit'].toUpperCase() + ' ' + step.remarks + '</b>'
                } else {
                  if (self.workoutsDict[step.workout_id]) {
                    html += '<br><b>' + self.workoutsDict[step.workout_id].name + '</b><br>' + self.getStepInfo(step)
                  }
                }
              })
              clearTimeout(self.overTrainPromise)
              self.trainPopoverActive = {
                index: index,
                html: html,
              }
            }
          }, 300)
        }
      }
    },
    cancelOverTrain: function () {
      if (this.showHoverDaySteps) {
        clearTimeout(this.overTrainPromise)
        this.overTrainActive = false
        this.trainPopoverActive = null
      }
    },
    closeTrainPopover: function () {
      if (this.showHoverDaySteps) {
        this.trainPopoverActive = null
      }
    },
    detectNotesSaved: function (event) {
      try {
        if (event && event.data && event.data.indexOf('setImmediate') !== 0) {
          const data = JSON.parse(event.data)
          if (data && data.event === 'client-notes-saved') {
            this.planValidation()
          }
        }
      } catch {}
    },
    activeEventListener: function () {
      if (this.client && this.excludeExercises) {
        window.addEventListener('message', this.detectNotesSaved)
      }
    },
    removeEventListener: function () {
      if (this.client && this.excludeExercises) {
        window.removeEventListener('message', this.detectNotesSaved)
      }
    },
    destroyValidation: function (next) {
      if ((this.showSaveOnlyInitialStep || this.showExitWarning) && this.changes.length && !this.saved && this.editPermission) {
        return this.$confirm(
          window.strings['are_you_sure_leave_training_plan_without_saving'],
          '',
          'warning',
          Utils.getAlertOptions(true, true),
        )
          .then(() => {
            Utils.setStorage('trainingPlan', null)
            next()
          })
          .catch(() => {
            next(false)
          })
      }
      Utils.setStorage('trainingPlan', null)
      next()
    },
    dayCategoryVisible: function (category, index) {
      if (category.show_has_value) {
        const dayFound = this.trainingPlan.days.find(function (d) {
          return !d.week && d.category === category.value
        })
        if (!dayFound) {
          if (this.dayCategoryActive === index) {
            this.dayCategoryActive = this.dayCategories.findIndex(function (d) {
              return !d.show_has_value
            })
          }
          return false
        }
      }
      return true
    },
    setExcludeExercise: function (id, include) {
      try {
        const ref =
          this.$refs && this.$refs.trainNotes ? this.$refs.trainNotes : this.$refs && this.$refs.daysNotes ? this.$refs.daysNotes : null
        if (ref) {
          const notes = this.getClientNotes()
          if (!notes.exclude_exercises) {
            notes.exclude_exercises = []
          }
          const index = notes.exclude_exercises.indexOf(id)
          if (include) {
            if (index === -1) {
              notes.exclude_exercises.push(id)
            }
          } else {
            if (index > -1) {
              notes.exclude_exercises.splice(index, 1)
            }
          }
          ref.updateNotes(notes, this.planValidation)
        }
      } catch {}
    },
    showDayStepsAccordion: function (day) {
      const index = this.daysAccordionClosed.indexOf(day.id)
      if (index > -1) {
        this.daysAccordionClosed.splice(index, 1)
      } else {
        this.daysAccordionClosed.push(day.id)
      }
    },
    handleSaveChangesFeedback: function () {
      const self = this
      Api.getLastPhysicalEvaluation(
        {
          id: this.client.dbId,
          useCache: true,
        },
        function (response) {
          if (response.success && response.data && response.data[0]) {
            response.data[0].changes +=
              '\n' +
              self.saveChangesFeedback.key +
              (self.saveChangesFeedback.date ? ' - ' + moment().format('YYYY-MM-DD HH:mm') : '') +
              '\n'
            Api.editPhysicalEvaluation(
              {
                client_id: self.client.dbId,
                id: response.data[0].id,
                changes: response.data[0].changes,
              },
              function () {},
            )
          }
        },
      )
    },
    changesKeys: function () {
      return {
        dayAdded: function (day) {
          return '• ' + window.strings['train_added'] + (day ? ' ' + day : '')
        },
        dayPasted: function (day) {
          return '• ' + window.strings['day_pasted'] + (day ? ' ' + day : '')
        },
        dayDuplicated: function (day) {
          return '• ' + window.strings['day_duplicated'] + (day ? ' ' + day : '')
        },
        dayDeleted: function (day) {
          return '• ' + window.strings['day_deleted'] + (day ? ' ' + day : '')
        },
        circuitUpdated: function (train, circuit) {
          return (
            '• ' +
            window.strings['circuit_updated'] +
            (circuit ? ' ' + circuit : '') +
            ' ' +
            window.strings['of_the_training'] +
            ' ' +
            train
          )
        },
        stepUpdated: function (train, step) {
          return (
            '• ' + window.strings['exercise_updated'] + (step ? ' ' + step : '') + ' ' + window.strings['of_the_training'] + ' ' + train
          )
        },
        stepDuplicated: function (train, step) {
          return (
            '• ' + window.strings['exercise_duplicated'] + (step ? ' ' + step : '') + ' ' + window.strings['of_the_training'] + ' ' + train
          )
        },
        stepDeleted: function (train, step) {
          return (
            '• ' + window.strings['exercise_deleted'] + (step ? ' ' + step : '') + ' ' + window.strings['of_the_training'] + ' ' + train
          )
        },
        trainsExercisesUpdated: function (trains) {
          return '• ' + window.strings['exercises_updated'] + (trains ? ' ' + trains : '')
        },
        weekUpdated: function (week) {
          return '• ' + window.strings['week_updated'] + (week ? ' ' + week : '')
        },
      }
    },
    mounted: function () {
      this.checkMountedCallback()
    },
    checkMountedCallback: function () {
      if (this.mountedCallback) {
        this.mountedCallback()
      }
    },
  },
}
</script>
